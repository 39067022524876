import React, { useEffect, useState } from "react"
import {
  Container,
  Grid,
  makeStyles,
  Typography,
  FormControlLabel,
} from "@material-ui/core"
import { CheckboxWrapper } from "./Elements"
import { TextFieldWrapper } from "./Elements";

const useStyles = makeStyles(theme => ({
  borderTable: {
    border: "1px solid #d1d1d1",
    margin: "15px 0px",
    padding: theme.spacing(2),
    backgroundColor:"#fff",
    borderRadius:"5px",
    boxShadow:"0 3px 10px 0 rgb(0 0 0 / 5%)"
  },
  tableStyles: {
    padding: "3px 0 0 15px",
  },
  labelWidth: {
    display: "inline-block",
    width: 300,
    fontWeight: "bold",
  },
  verticalAlign: {
    verticalAlign: "baseline",
    padding:"0"
  },
}))

const data = [
  {
    name:"ReferEyeSpecialist", label: "Refer Eye Specialist for Vision correction",
    tableType: 9,value:"",
  },
  {
    name:"DecreaseStress", label: "Decrease stress with relaxation exercise",
    tableType: 9,value:"",
  },
  {
    name:"AvoidOilyFood", label: "Avoid Spicy, oily food",
    tableType: 9,value:"",
  },
  {
    name:"DrinkFluids", label: "Drink Plenty of Fluids",
    tableType: 9,value:"",
  },
  {
    name:"StopSmoking", label: "Stop Smoking & consuming Too Much of Alcohol",
    tableType: 9,value:"",
  },
  {
    name:"MaintainWeight", label: "Maintain Healthy Weight",
    tableType: 9,value:"",
  },
  {
    name:"EatNutBalDiet", label: "Eat Nutritious And Balance Diet",
    tableType: 9,value:"",
  },
]
const TableNinth = props => {
  const classes = useStyles()
  var verticalAlign = classes.verticalAlign
  var tableStyles = classes.tableStyles
  

  const [fieldData, setFieldData]=useState(data)
  useEffect(() => {
      if(props.data!=undefined && props.data!={}){
        var dataFromDb = []
        fieldData.map((field,index)=>{
          field["value"] = props.data[field["name"]]==undefined?"":props.data[field["name"]]
          dataFromDb.push(field)
        })
        setFieldData(dataFromDb)

      }
    
  }, [props.data])

  const CheckboxComponent = props => {
    const { index, label,name,value } = props
    
    return (
      <Grid item sm={12} className={tableStyles}>
        <Grid container alignItems='baseline'>
          <Grid item sm={1} className={classes.labelWidth}>
            {index}
          </Grid>
          <Grid item sm={5} className={classes.labelWidth}>
            {label}
          </Grid>
          <Grid item sm={2}>
            <FormControlLabel
              control={
                <CheckboxWrapper label={label} name={name} value={value} classes={verticalAlign} />
              }
            />
          </Grid>
        </Grid>
      </Grid>
    )
  }
  return (
    <>
      <Typography align='center' variant='h6'>
        ADVICE/ REMARKS
      </Typography>
      <Container className={classes.borderTable}>
        <Grid container>
          {fieldData.map((eachfield, index) => {
            const prop = { ...eachfield, index: index + 1 }
            return <CheckboxComponent {...prop} key={index} />
          })}
        </Grid>
        <p style={{ marginBottom: 10,marginTop : 30,fontWeight : 'bold' }}>General Physician Consultation</p>
        <TextFieldWrapper
            label={"GeneralPhysicianConsultation"}
            disableUnderline={false}
            name={"GeneralPhysicianConsultation"}
            width={"100%"}
            style={{marginBottom : '20px'}}
            value={props.consultation}
            onChange={(e) => {
             props.setConsulation(e.target.value);
            }}
          />
        <div className="m-5" style={{textAlign:"right"}}>
            <p>Doctor Signature</p>
        </div>
        
      </Container>
    </>
  )
}

export default TableNinth
