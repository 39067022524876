import React, { Component } from "react";
import Home from "./Home";
import Dashboard from "./Dashboard";
import SearchEmployee from "./SearchEmployee";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import Users from "./auth/Users";
import { Authentication } from "./auth/Authenticatoin";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Swal from "sweetalert2";
import Modal from "@material-ui/core/Modal";
import BeatLoader from "react-spinners/BeatLoader";
import jwt from "jwt-decode";
import config from "config";
import DateWiseReport from "./reports/Reports";
import DateWiseUserReport from "./reports/DateWiseUserReport"
import UserWiseEntries from "./reports/UserWiseEntries";
class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: this.tabs.home,
      authUser: Authentication.authUserVlaue,
      passwordChange: false,
      newPassword: "",
      repeatPassword: "",
      submitted: false,
      loaderModel: false,
    };
  }
  handleCurrentTab(currentTab) {
    this.setState({ currentTab: currentTab });
  }
  //  total we have 3 tabs
  //  try to create independent component as much as possible
  //  dont pass any props
  tabs = {
    home: "home",
    dashboard: "New Registration",
    search: "search",
    users: "users",
    reports: "reports",
    myreports: "myreports",
    userwiseentries : 'userwiseentries'
  };
  //  here we are doing component mapping for individual tabs
  ComponentMapping() {
    const key = this.state.currentTab;
    switch (key) {
      case this.tabs.home:
        return (
          <Home
            goToDashboard={() => this.handleCurrentTab(this.tabs.dashboard)}
          />
        );
      case this.tabs.dashboard:
        return (
          <Dashboard topButtons={true} bottomButtons={true} action="new" />
        );
      case this.tabs.users:
        return <Users />;
      case this.tabs.search:
        return <SearchEmployee />;
      case this.tabs.reports:
        return <DateWiseReport />;
        case this.tabs.myreports:
          return <DateWiseUserReport />;
          case this.tabs.userwiseentries:
            return <UserWiseEntries />;
      default:
        return (
          <Home
            goToDashboard={() => this.handleCurrentTab(this.tabs.dashboard)}
          />
        );
    }
  }

  headers() {
    return (
      <header
        className="navbar pcoded-header hide"
        style={{
          position: "fixed",
          width: "100%",
          marginLeft: "0",
          backgroundColor: "#3f4d67",
        }}
      >
        <div
          className="navbar-collapse"
          style={{
            width: "100%",
            marginLeft: "0",
            backgroundColor: "#3f4d67",
          }}
        >
          <ul
            className="navbar-nav mr-auto"
            style={{ flexDirection: "row", padding: "25px" }}
          >
            <a href="index.html" className="b-brand">
              <div className="b-bg">
                <span className="b-title" style={{ color: "white" }}>
                  Amara Hospitals
                </span>
              </div>
            </a>
          </ul>

          <ul className="navbar-nav ml-auto" style={{ flexDirection: "row" }}>
            <li>
              <div className="dropdown drp-user">
                <a
                  href="#"
                  style={{ color: "white" }}
                  className="dropdown-toggle"
                  data-toggle="dropdown"
                >
                  <i className="icon feather icon-settings"></i>
                </a>
                <div className="dropdown-menu dropdown-menu-right profile-notification">
                  <div className="pro-head">
                    <span>{"Test User"}</span>
                    <a
                      href="#"
                      onClick={() => {
                        Authentication.logout();
                      }}
                      className="dud-logout"
                      title="Logout"
                    >
                      <i className="feather icon-log-out"></i>
                    </a>
                  </div>
                  <ul className="pro-body">
                    <li>
                      <a
                        href="#"
                        onClick={(e) => {
                          this.setState({ passwordChange: true });
                        }}
                        className="dropdown-item"
                      >
                        <i className="feather icon-settings"></i> Change
                        Password
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </header>
    );
  }
  sidebars() {
    return (
      <nav
        className="pcoded-navbar hide"
        style={{ top: "70px", position: "fixed" }}
      >
        <div className="navbar-wrapper">
          <div
            className="navbar-content scroll-div"
            style={{ backgroundColor: "#3f4d67" }}
          >
            <ul className="nav pcoded-inner-navbar">
              <li className="nav-item pcoded-menu-caption">
                <label>Main</label>
              </li>
              <li
                className={`nav-item ${
                  this.state.currentTab === this.tabs.home
                    ? "pcoded-trigger"
                    : ""
                }`}
              >
                <Link
                  to="/"
                  className="nav-link"
                  onClick={() => {
                    this.handleCurrentTab(this.tabs.home);
                  }}
                >
                  <span className="pcoded-micon">
                    <i className="feather icon-home"></i>
                  </span>
                  <span className="pcoded-mtext">Home</span>
                </Link>
              </li>
              <li
                className={`nav-item ${
                  this.state.currentTab === this.tabs.dashboard
                    ? "pcoded-trigger"
                    : ""
                }`}
              >
                <Link
                  to="/"
                  onClick={() => {
                    this.handleCurrentTab(this.tabs.dashboard);
                  }}
                  className={`nav-link`}
                >
                  <span className="pcoded-micon">
                    <i className="feather icon-credit-card"></i>
                  </span>
                  <span className="pcoded-mtext">New Registration</span>
                </Link>
              </li>
              <li
                className={`nav-item ${
                  this.state.currentTab === this.tabs.search
                    ? "pcoded-trigger"
                    : ""
                }`}
              >
                <Link
                  className="nav-link"
                  to="/"
                  onClick={() => {
                    this.handleCurrentTab(this.tabs.search);
                  }}
                >
                  <span className="pcoded-micon">
                    <i className="feather icon-search"></i>
                  </span>
                  <span className="pcoded-mtext">Search Employee</span>
                </Link>
              </li>
              {(this.state.authUser.role == 1 ||
                this.state.authUser.role == 2) && (
                <li
                  className={`nav-item ${
                    this.state.currentTab === this.tabs.users
                      ? "pcoded-trigger"
                      : ""
                  }`}
                >
                  <Link
                    className="nav-link"
                    to="/"
                    onClick={() => {
                      this.handleCurrentTab(this.tabs.users);
                    }}
                  >
                    <span className="pcoded-micon">
                      <i className="feather icon-user"></i>
                    </span>
                    <span className="pcoded-mtext">Users</span>
                  </Link>
                </li>
              )}
              <li
                className={`nav-item ${
                  this.state.currentTab === this.tabs.myreports
                    ? "pcoded-trigger"
                    : ""
                }`}
              >
                <Link
                  className="nav-link"
                  to="/"
                  onClick={() => {
                    this.handleCurrentTab(this.tabs.myreports);
                  }}
                >
                  <span className="pcoded-micon">
                    <i className="feather icon-file-text"></i>
                  </span>
                  <span className="pcoded-mtext">My Reports</span>
                </Link>
              </li>
              <li
                className={`nav-item ${
                  this.state.currentTab === this.tabs.reports
                    ? "pcoded-trigger"
                    : ""
                }`}
              >
                <Link
                  className="nav-link"
                  to="/"
                  onClick={() => {
                    this.handleCurrentTab(this.tabs.reports);
                  }}
                >
                  <span className="pcoded-micon">
                    <i className="feather icon-file"></i>
                  </span>
                  <span className="pcoded-mtext">Reports</span>
                </Link>
              </li>
              <li
                className={`nav-item ${
                  this.state.currentTab === this.tabs.userwiseentries
                    ? "pcoded-trigger"
                    : ""
                }`}
              >
                <Link
                  className="nav-link"
                  to="/"
                  onClick={() => {
                    this.handleCurrentTab(this.tabs.userwiseentries);
                  }}
                >
                  <span className="pcoded-micon">
                    <i className="feather icon-file"></i>
                  </span>
                  <span className="pcoded-mtext">User Wise Entries</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
  mainComponent() {
    return (
      <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper" />
                {this.ComponentMapping()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleSubmit = (e) => {
    this.setState({ loaderModel: true });
    this.setState({ submitted: true });
    setTimeout(() => this.setState({ submitted: false }), 5000);

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + this.state.authUser.accessToeken,
      },
      body: JSON.stringify({ password: this.state.newPassword }),
    };

    fetch(`${config.apiUrl}` + "apimain/changepass", requestOptions)
      .then((response) => {
        this.setState({ loaderModel: false });
        if (response.status > 400) {
          if (response.status == 401) {
            Authentication.logout();
          }
          Swal.fire("Error!", "Error While Processing", "error");
        }
        return response.json();
      })
      .then((data) => {
        if (data != undefined) {
          this.setState({ loaderModel: false });
          if ("Success" in data) {
            Swal.fire("Deleted!", data["Errors"], "success");
            this.setState({ passwordChange: false });
          } else {
            Swal.fire({ icon: "error", title: "Error", text: data["Errors"] });
          }
        }
      });
  };

  changeRepassword = (e) => {
    this.setState({ repeatPassword: e.target.value });
    if (!ValidatorForm.hasValidationRule("isPasswordMatch")) {
      ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
        if (value !== this.state.newPassword) {
          return false;
        }
        return true;
      });
    }
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.state.authUser) {
      var current_time = Date.now() / 1000;
      if (jwt(this.state.authUser.accessToeken).exp < current_time) {
        Authentication.logout();
      }
    }
  }

  render() {
    const inputStyle = {
      style: { fontSize: 14, fontfamily: "Open Sans", marginTop: "15px" },
    };
    const color = "#f50057";
    const loading = true;
    return (
      <>
        <Modal
          open={this.state.loaderModel}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <BeatLoader color={color} loading={loading} size={17} />
        </Modal>
        <Modal
          open={this.state.passwordChange}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="card">
            <div className="card-body text-center">
              <h4 className="mb-4">Change Password</h4>
              <ValidatorForm
                style={{ width: "100%", padding: "0 0 15px 0" }}
                onSubmit={(e) => {
                  this.handleSubmit(e);
                }}
              >
                <TextValidator
                  InputLabelProps={inputStyle}
                  InputProps={inputStyle}
                  variant="outlined"
                  size="small"
                  label="Password"
                  onChange={(e) =>
                    this.setState({ newPassword: e.target.value })
                  }
                  value={this.state.newPassword}
                  name="password"
                  type="password"
                  validators={["required", "minStringLength:6"]}
                  errorMessages={[
                    "this field is required",
                    "password should have atleast 6 charecters",
                  ]}
                  fullWidth
                />

                <TextValidator
                  InputLabelProps={inputStyle}
                  InputProps={inputStyle}
                  variant="outlined"
                  size="small"
                  label="Repeat password"
                  onChange={(e) => this.changeRepassword(e)}
                  name="repeatPassword"
                  type="password"
                  validators={["isPasswordMatch", "required"]}
                  value={this.state.repeatPassword}
                  errorMessages={[
                    "password mismatch",
                    "this field is required",
                  ]}
                  fullWidth
                />

                <Button
                  color="secondary"
                  variant="contained"
                  type="submit"
                  disabled={this.state.submitted}
                  style={{ marginTop: "20px" }}
                >
                  {(this.state.submitted && "Loading..!") ||
                    (!this.state.submitted && "Submit")}
                </Button>
                <input
                  style={{ marginTop: "15px" }}
                  onClick={(e) =>
                    this.setState({
                      newPassword: "",
                      repeatPassword: "",
                      passwordChange: false,
                    })
                  }
                  type="reset"
                  className="btn btn-secondary m-l-10"
                  value="Close"
                />
              </ValidatorForm>
            </div>
          </div>
        </Modal>

        {this.headers()}
        {this.sidebars()}
        {this.mainComponent()}
      </>
    );
  }
}

export default Layout;
