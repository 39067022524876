import React, { useEffect, useState } from "react"
import { Container, Grid, makeStyles, Typography } from "@material-ui/core"
import { TextFieldWrapper } from "./Elements"

const useStyles = makeStyles(theme => ({
  borderTable: {
    border: "1px solid #d1d1d1",
    margin: "15px 0px",
    padding: theme.spacing(2),
    backgroundColor:"#fff",
    borderRadius:"5px",
    boxShadow:"0 3px 10px 0 rgb(0 0 0 / 5%)"
  },
  labelWidth: {
    display: "inline-block",
    width: 300,
    fontWeight: "bold",
  },
  verticalAlign: {
    verticalAlign: "baseline",
  },
  tableStyles: {
    borderTop: "1px solid ",
  },
}))

const data = [
  {
    label: "SEDENTARY WORK",
    name:"Sedantry",
    type: "text",
    size: 2,
    tableType: 8,
    value:{SedantryActivity:"", SedantryTotalCal:""},
  },
  {
    label: "MODERATE WORK",
    name:"Moderate",
    type: "text",
    size: 3,
    tableType: 8,
    value:{ModerateActivity:"", ModerateTotalCal:""},
  },
  {
    label: "HEAVY WORK",
    name:"Heavy",
    type: "text",
    size: 6,
    tableType: 8,
    value:{HeavyActivity:"", HeavyTotalCal:""},
  },
]
const TableSeventh = props => {
  const classes = useStyles()
  var verticalAlign = classes.verticalAlign
  var tableStyles = classes.tableStyles

  const [fieldData, setFieldData]=useState(data)

  useEffect(() => {
    if(props.data!=undefined && props.data!={}){
      var dataFromDb = []
      fieldData.map((field,index)=>{
        var values = field["value"]
        Object.keys(values).map((header)=>{
          values[header] = props.data[header]==undefined?"":props.data[header]
        })
        field["value"] = values
        dataFromDb.push(field)
      })
      setFieldData(dataFromDb)
    }
  
  }, [props.data])

  const TextValuesComponent = props => {
    var { label,name,value } = props
    var columnStyles = { borderLeft: "1px solid black", padding: "0px 10px" }

    return (
      <Grid item sm={12} className={tableStyles}>
        <Grid container alignContent='center'>
          <Grid
            item
            sm={2}
            className={classes.labelWidth}
            style={{ paddingLeft: 10 }}
          >
            <span> {label}</span>
          </Grid>
          {[
            {name:"Activity", labelName: "ACTIVITY", size: 3 },
            {name:"TotalCal", labelName: "TOTAL CALORIES NEEDED PER DAY", size: 6 },
          ].map((obj, index) => {
            var labelName = label + "_" + obj.labelName
            return (
              <Grid item sm={obj.size} style={columnStyles} key={index}>
                <TextFieldWrapper
                  label={labelName}
                  classes={verticalAlign}
                  disableUnderline={true}
                  name={name+obj.name}
                  value={value[name+obj.name]}
                />
              </Grid>
            )
          })}
        </Grid>
      </Grid>
    )
  }

  return (
    <Container className={classes.borderTable}>
      <Grid container>
        {[
          { name: "TYPE", size: 2 },
          { name: "ACTIVITY", size: 3 },
          { name: "TOTAL CALORIES NEEDED PER DAY", size: 6 },
        ].map((value, index) => {
          return (
            <Grid
              item
              sm={value.size}
              key={index}
              style={{ borderLeft: index === 0 ? "0px" : "1px solid black" }}
            >
              <Typography align='center' style={{ fontWeight: "bold" }}>
                {value.name}
              </Typography>
            </Grid>
          )
        })}
      </Grid>
      <Grid container>
        {fieldData.map((eachfield, index) => {
          return <TextValuesComponent {...eachfield} key={index} />
        })}
      </Grid>
    </Container>
  )
}

export default TableSeventh
