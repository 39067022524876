import React, { Component, useState, useEffect } from "react";
import { DateFieldWrapper } from "../Dashboard/Elements";
import { Container, Grid, makeStyles, TextField } from "@material-ui/core";
import moment from "moment";
import { Pagination } from "@mui/material";
import { Authentication } from "../auth/Authenticatoin";
import config from "config";
import Modal from '@material-ui/core/Modal';

import BeatLoader from "react-spinners/BeatLoader";
const useSelectStyles = makeStyles((theme) => ({
  root: {
    width: 200,
  },
}));

const useStyles = makeStyles((theme) => ({
  borderTable: {
    border: "1px solid #d1d1d1",
    margin: "0 0px",
    padding: theme.spacing(1),
    backgroundColor: "#fff",
    borderRadius: "5px",
    boxShadow: "0 3px 10px 0 rgb(0 0 0 / 5%)",
  },
  tableStyles: {
    padding: "0 0 0 15px",
  },
  labelWidth: {
    display: "inline-block",
    width: 80,
    fontWeight: "bold",
  },
  typeWidth: {
    margin: theme.spacing(1),
  },
  verticalAlign: {
    verticalAlign: "baseline",
  },
}));

const data = {
  Success: "True",
  total: 400,
  data: [
    {
      EmployeeId: "1017853",
      Name: "Puvvaram Lalitha",
      MRNNumber: "AH0000109275",
      Department: "Post Injection Moulding",
      Age: "30",
      Experience: "1",
      Location: "Petamitta",
      DateOfBirth: "1994-04-01",
      DateOfJoining: "2023-05-01",
    },
    {
      EmployeeId: "1017850",
      Name: "Manikandan. A",
      MRNNumber: "AH0000109271",
      Department: "Quality Assurance",
      Age: "27",
      Experience: "1",
      Location: "Petamitta",
      DateOfBirth: "1996-05-25",
      DateOfJoining: "2023-04-17",
    },
    {
      EmployeeId: "1017901",
      Name: "Madhavi Chinthamakula",
      MRNNumber: "AH0000109322",
      Department: "Post Injection Moulding",
      Age: "27",
      Experience: "3",
      Location: "Petamitta",
      DateOfBirth: "1997-03-22",
      DateOfJoining: "2023-10-01",
    },
    {
      EmployeeId: "1017833",
      Name: "Vepareddy Jyothiswar Reddy",
      MRNNumber: "AH0000109265",
      Department: "Maintenance",
      Age: "23",
      Experience: "1",
      Location: "Petamitta",
      DateOfBirth: "2000-12-01",
      DateOfJoining: "2023-03-10",
    },
    {
      EmployeeId: "1017827",
      Name: "Pallapolla Ruma",
      MRNNumber: "AH0000109256",
      Department: "Injection Molding",
      Age: "26",
      Experience: "1",
      Location: "Petamitta",
      DateOfBirth: "1997-08-10",
      DateOfJoining: "2023-03-01",
    },
    {
      EmployeeId: "1017913",
      Name: "Rajolla Dharani",
      MRNNumber: "AH0000109228",
      Department: "Injection Molding",
      Age: "31",
      Experience: "3",
      Location: "Petamitta",
      DateOfBirth: "1992-05-25",
      DateOfJoining: "2023-11-01",
    },
    {
      EmployeeId: "1017803",
      Name: "Pethapaneni Rekha",
      MRNNumber: "AH0000109249",
      Department: "Production",
      Age: "29",
      Experience: "1",
      Location: "Petamitta",
      DateOfBirth: "1994-06-25",
      DateOfJoining: "2023-02-01",
    },
    {
      EmployeeId: "1017983",
      Name: "Pagadala Manoj",
      MRNNumber: "AH0000109308",
      Department: "Components - Stores",
      Age: "28",
      Experience: "1.5",
      Location: "Petamitta",
      DateOfBirth: "1996-02-07",
      DateOfJoining: "2024-02-01",
    },
    {
      EmployeeId: "1017973",
      Name: "Amasa Gowri Sankar",
      MRNNumber: "AH0000117636",
      Department: "Production",
      Age: "20",
      Experience: "3.5",
      Location: "Petamitta",
      DateOfBirth: "2004-02-16",
      DateOfJoining: "2024-01-01",
    },
    {
      EmployeeId: "1018097",
      Name: "Gayathri. A",
      MRNNumber: "AH0000109329",
      Department: "Post Injection Moulding",
      Age: "30",
      Experience: "1.5",
      Location: "Petamitta",
      DateOfBirth: "1993-06-30",
      DateOfJoining: "2024-03-01",
    },
    {
      EmployeeId: "1017963",
      Name: "Ballipagu Dinesh Varma",
      MRNNumber: "AH0000109301",
      Department: "Production",
      Age: "20",
      Experience: "2",
      Location: "Petamitta",
      DateOfBirth: "2003-08-15",
      DateOfJoining: "2024-01-01",
    },
    {
      EmployeeId: "1017951",
      Name: "Chandragiri Purnima",
      MRNNumber: "AH0000109285",
      Department: "Quality Assurance",
      Age: "31",
      Experience: "2",
      Location: "Petamitta",
      DateOfBirth: "1992-06-15",
      DateOfJoining: "2023-12-01",
    },
    {
      EmployeeId: "1017952",
      Name: "Sumathi. N",
      MRNNumber: "AH0000109289",
      Department: "Post Injection Moulding",
      Age: "36",
      Experience: "2",
      Location: "Petamitta",
      DateOfBirth: "1988-03-30",
      DateOfJoining: "2023-12-01",
    },
  ],
};

const headers = [
  "Employee Id",
  "Name",
  "Overall Entries",
  "Today Entries",
  
];

const UserWiseEntries = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(
    moment(new Date()).format("yyyy-MM-DD")
  );
  const authUser = Authentication.authUserVlaue;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [total, setTotal] = useState(0);
  const [totalEmployee, setTotalEmployee] = useState(0);
  const [newReg,setNewReg] = useState(0)
  const [newRegToday,setNewRegToday] = useState(0)

  console.log(selectedDate);
  const fetchData = async (d) => {
    try {
      console.log("fetchData", selectedDate);
      setLoading(true)
      const response = await fetch(
        `${config.apiUrl}apimain/employees/user-wise-entries?d=${d}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "JWT " + authUser.accessToeken,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      console.log(responseData.data)
      setData(responseData.data);
     
  
    } catch (error) {
      console.log(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData(selectedDate);
  }, [selectedDate, currentPage]);

  const handleNextPage = async () => {
    setCurrentPage(page + 1);
  };

  const handleDateChange = async (date) => {
    setSelectedDate(date);
  };

 
  // if (error) return <div>Error: {error}</div>;
  const classes = useStyles();
  const SelectStyles = useSelectStyles();
  return (
    <div style={{ width: "100%", overflowX: "auto", padding: "24px 0px" }}>
      <Modal
        open={loading}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{display:'flex',alignItems:'center',justifyContent:'center'}} >
            
        <BeatLoader color="#f50057" loading={true} size={17} />
      </Modal>
     <div
        style={{
          display: "flex",
          justifyContent: "end",
          padding: "10px",
          alignItems: "center",
          gap: '120px'
        }}
      >
         <div
          style={{
            border: "1px solid #d7d7d7",
            padding: "10px ",
            borderRadius: "10px",
            fontWeight: 600,
            color: "black",
            fontSize: "16px",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          }}
        >
          <span className={classes.typeWidth}>
            <TextField
              classes={SelectStyles.root}
              label={"Select Date"}
              type={"date"}
              defaultValue={moment(selectedDate).format("yyyy-MM-DD")}
              name={"Select Date"}
              value={moment(selectedDate).format("yyyy-MM-DD")}
              onChange={async (e) => {
                await handleDateChange(
                  moment(e.target.value).format("yyyy-MM-DD")
                );
                await fetchData(moment(e.target.value).format("yyyy-MM-DD"));
              }}
            />
          </span>
        </div>
      </div>

    
      <div style={{ padding: "24px 0px" }}>
        {data.length > 0 ? (
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead style={{ backgroundColor: "#c00245", color: "white" }}>
              <tr>
                {headers.map((header) => (
                  <th
                    key={header}
                    style={{ padding: "10px", border: "1px solid white" }}
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td style={{ padding: "10px", border: "1px solid black" }}>
                    {item.emp_id} 
                  </td>
                  <td style={{ padding: "10px", border: "1px solid black" }}>
                    {item.username}
                  </td>
                  {/* <td style={{ padding: "10px", border: "1px solid black" }}>
                    {item.role}
                  </td> */}
                  <td style={{ padding: "10px", border: "1px solid black" }}>
                    {item.total_modifications}
                  </td>
                  <td style={{ padding: "10px", border: "1px solid black" }}>
                    {item.modifications_today}
                  </td>
               
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
            
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "18px",
              color: "black",
              fontWeight: 700,
              textTransform: "uppercase",
            }}
          >
            Entries Not Found
          </div>
        )}
      </div>
      
    </div>
  );
};

export default UserWiseEntries;
