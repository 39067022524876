import React, { useEffect, useState } from "react";
import { Container, Grid, makeStyles, Typography } from "@material-ui/core";
import {
  TextFieldWrapper,
  DateFieldWrapper,
  NumberFieldWrapper,
  SelectFieldWrapper,
} from "./Elements";

const useSelectStyles = makeStyles((theme) => ({
  root: {
    width: 130,
  },
}));

const useStyles = makeStyles((theme) => ({
  borderTable: {
    border: "1px solid #d1d1d1",
    margin: "0 0px",
    padding: theme.spacing(2),
    backgroundColor: "#fff",
    borderRadius: "5px",
    boxShadow: "0 3px 10px 0 rgb(0 0 0 / 5%)",
  },
  tableStyles: {
    padding: "0 0 0 15px",
  },
  table2Styles: {
    border: "1px solid",
  },
  labelWidth: {
    display: "inline-block",
    width: "30%",
    fontWeight: "bold",
  },
  typeWidth: {
    margin: theme.spacing(5),
    "& .MuiFormControl-root": {
      width: "45%",
    },
  },
  verticalAlign: {
    verticalAlign: "baseline",
  },
  borderTable1: {
    border: "1px solid #d1d1d1",
    margin: "0 0px",
    padding: theme.spacing(2),
    backgroundColor: "#fff",
    borderRadius: "5px",
    boxShadow: "0 3px 10px 0 rgb(0 0 0 / 5%)",
  },
}));
const data = [
  {
    label: "Oral Cavity",
    name: "OralCavity",
    type: "text",
    size: 12,
    tableType: 5,
    value: "",
  },
  {
    label: "Tuning Fork Test",
    name: "TurningForTest",
    type: "text",
    size: 12,
    tableType: 5,
    value: "",
  },
  {
    label: "Defects/Disability",
    name: "DefectsDisability",
    type: "text",
    size: 12,
    tableType: 5,
    value: "",
  },
  {
    label: "Lymph Nodes",
    name: "LymphNodes",
    type: "text",
    size: 12,
    tableType: 5,
    value: "",
  },
  {
    label: "Pallor",
    name: "Pallor",
    type: "text",
    size: 12,
    tableType: 5,
    value: "",
  },
  {
    label: "De-Worming",
    name: "DeWorming",
    type: "select",
    list: ["Done", "Not Done"],
    size: 12,
    tableType: 5,
    value: "",
  },
  {
    label: "Vaccination",
    name: "Vaccination",
    type: "select",
    list: ["Given", "Not Given"],
    size: 12,
    tableType: 5,
    value: "",
  },
  {
    label: "    Name of the Vaccine",
    name: "NameOfTheVaccine",
    type: "text",
    size: 12,
    tableType: 5,
    value: "",
  },
  {
    label: "    Batch",
    name: "VacBatch",
    type: "text",
    size: 12,
    tableType: 5,
    value: "",
  },
  {
    label: "    Expiry Date ",
    name: "VacExpiryDate",
    type: "date",
    size: 12,
    tableType: 5,
    value: "",
  },
  {
    label: "Audiological Evaluation",
    name: "VacAudioEvaluation",
    type: "text",
    size: 12,
    tableType: 5,
    value: "Reports enclosed",
  },
];
const TableFive = (props) => {
  const classes = useStyles();
  const SelectStyles = useSelectStyles();

  const Item = (props) => {
    var tableStyles = classes.tableStyles;
    const { border, size, pageBreak, value, name, hide } = props;
    var table2Styles = border ? classes.table2Styles : "";

    return (
      <>
        <Grid
          item
          sm={size}
          className={tableStyles + " " + table2Styles}
          style={{ display: hide ? "none" : "block" }}
        >
          <Element {...props} />
        </Grid>
      </>
    );
  };
  const Element = (props) => {
    const { label, type, width, name, value } = props;

    var verticalAlign = classes.verticalAlign;
    var typeWidth = classes.typeWidth;
    var labelWidth = classes.labelWidth;

    if (["select"].includes(type)) {
      const { list } = props;
      return (
        <div style={{ maxHeight: "25px" }}>
          <span className={labelWidth}> {label}</span> :
          <span className={typeWidth}>
            <SelectFieldWrapper
              classes={SelectStyles.root}
              list={list}
              label={label}
              value={value}
              name={name}
            />
          </span>
        </div>
      );
    }
    if (["date"].includes(type)) {
      return (
        <div style={{ maxHeight: "25px" }}>
          <span className={labelWidth}> {label}</span> :
          <span className={typeWidth}>
            <DateFieldWrapper
              classes={SelectStyles.root}
              label={label}
              value={value}
              name={name}
            />
          </span>
        </div>
      );
    }
    if (["text"].includes(type)) {
      return (
        <div style={{ maxHeight: "25px" }}>
          <span className={labelWidth}> {label}</span> :
          <span className={typeWidth}>
            <TextFieldWrapper
              width={width}
              classes={verticalAlign}
              label={label}
              value={value}
              name={name}
            />
          </span>
        </div>
      );
    }
    if (["number"].includes(type)) {
      return (
        <div style={{ maxHeight: "25px" }}>
          <span className={labelWidth}> {label}</span> :
          <span className={typeWidth}>
            <NumberFieldWrapper
              type={type}
              label={label}
              value={value}
              name={name}
            />
          </span>
        </div>
      );
    }
  };

  const [fieldData, setFieldData] = useState(data);

  useEffect(() => {
    if (props.data != undefined && props.data != {}) {
      var dataFromDb = [];
      fieldData.map((field, index) => {
        field["value"] =
          props.data[field["name"]] == undefined
            ? ""
            : props.data[field["name"]];
        dataFromDb.push(field);
      });
      setFieldData(dataFromDb);
    }
  }, [props.data]);

  console.log(props.data.IsFSSI);
  return (
    <>
      <Typography align="center" variant="h6">
        PHYSICAL EXAMINATION
      </Typography>
      <Container className={classes.borderTable1}>
        <Grid container>
          {fieldData.map((eachfield, index) => {
            {
              if (eachfield.name !== "VacExpiryDate") {
                return <Item {...eachfield} key={index} />;
              } else {
                if (props.data["IsFSSI"] === true) {
                  return <Item {...eachfield} key={index} />;
                } else {
                  return (
                    <Item
                      {...eachfield}
                      key={index}
                      style={{ display: "none" }}
                      hide
                    />
                  );
                }
              }
            }
          })}
        </Grid>
      </Container>
    </>
  );
};

export default TableFive;
