import React,{Component} from 'react'
import {ValidatorForm,TextValidator} from 'react-material-ui-form-validator';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid'
import { Button } from '@material-ui/core';
import Swal from 'sweetalert2'
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import {Authentication} from './Authenticatoin'
import config from 'config'
import jwt from "jwt-decode";
import BeatLoader from "react-spinners/BeatLoader";
import Modal from '@material-ui/core/Modal';

class Users extends Component{

    constructor(props) {
        super(props);
        this.state = {
          usersList: [],
          formDataNew:{
            FirstName:"",
            LastName:"",
            Name:"",
            PreferredName:"",
            Email:"",
            Role:""
          },
          formDataEdit:{
            FirstName:"",
            LastName:"",
            Name:"",
            PreferredName:"",
            Email:"",
            Role:"",
            EmployeeId:""
          }, 
          autoCompleteList:[],
          authUser:Authentication.authUserVlaue,
          loaderModel:false,
          selectedUser:{},
          submitted:false,
          submittednew:false,
          cardsTotalCount:0,
          newPassword:"",
          repeatPassword:"",
          passwordChange:false,
          selectedEmployeeId:"",
          submittedpass:false
        };
    };
    
    handleClose = () => {
        this.setState({loaderModel:false});
    };

    onStaffIdSelect= (event, value)=>{
        const selectedObject = this.state.autoCompleteList[Object.keys(this.state.autoCompleteList).filter(key => this.state.autoCompleteList[key].EmployeeId == value)]
        
        const { formDataEdit } = this.state;
        formDataEdit['EmployeeId'] = selectedObject["EmployeeId"];
        formDataEdit['FirstName'] = selectedObject["FirstName"];
        formDataEdit['LastName'] = selectedObject["LastName"];
        formDataEdit['Name'] = selectedObject["Name"];
        formDataEdit['PreferredName'] = selectedObject["PreferredName"];
        formDataEdit['Email'] = selectedObject["Email"];
        formDataEdit['Role'] = selectedObject["Role"];

        this.setState({ formDataEdit });
        
        if(selectedObject){
            this.setState(() => { return { 
                selectedUser: selectedObject,
                autoCompleteList:[]
            }; 
        });
        }
    }
    
    handleChangeStaffId =(event)=>{
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', Authorization:'JWT '+ this.state.authUser.accessToeken },        
        };
        
        fetch(`${config.apiUrl}`+"apimain/usersselected?str="+event.target.value,requestOptions)
            .then(response => {
                this.setState({loaderModel:false});
                if (response.status > 400) {
                    if(response.status==401){
                        Authentication.logout()
                    }
                    Swal.fire('Error!','Error While Processing','error');
                }
                return response.json();
        })
        .then(data => {
            if(data!=undefined){
                if("Success" in data){
                    this.setState({autoCompleteList: data.data})
                }
                if("Fail" in data){
                    var errors =""
                    data["Errors"].map(x=>errors+=x)
                    Swal.fire('Error!',errors,'error');
                }
            }
        });
    }

    handleChangeNew =(event)=>{
        const { formDataNew } = this.state;
        formDataNew[event.target.name] = event.target.value;
        this.setState({ formDataNew });
    }

    handleEditChange =(event)=>{
        const { formDataEdit } = this.state;
        formDataEdit[event.target.name] = event.target.value;
        this.setState({ formDataEdit });
    }

    deleteUser=(serialid)=>{
        
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            this.setState({loaderModel:true});
            if (result.isConfirmed) {
                const requestOptions = {
                    method: 'DELETE',
                    headers: { 'Content-Type': 'application/json', Authorization:'JWT '+ this.state.authUser.accessToeken },       
                    body: JSON.stringify({'employeeId':serialid})
                };
                
                fetch(`${config.apiUrl}`+"apimain/users",requestOptions)
                    .then(response => {
                    
                    this.setState({loaderModel:false});
                    if (response.status > 400) {
                        if(response.status==401){
                            Authentication.logout()
                        }
                        Swal.fire('Error!','Error While Processing','error');
                    }
                    return response.json();
                })
                .then(data => {
                    this.setState({loaderModel:false});
                    if(data!=undefined){
                        if("Success" in data){
                            Swal.fire('Deleted!',data["Errors"],'success')
                            this.fetchUsersData();    
                        }else{
                            this.setState({loaderModel:false});
                            Swal.fire({icon: 'error',title: 'Error',text: data["Errors"]})
                        }
                    }
                });
            }else{
                this.setState({loaderModel:false});
            }
          })
    }

    handleSubmitEdit=()=>{
        this.setState({loaderModel:true});
        this.setState({ submitted: true }, () => {
            setTimeout(() => this.setState({ submitted: false }), 5000);
            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json', Authorization:'JWT '+ this.state.authUser.accessToeken },
                body: JSON.stringify(this.state.formDataEdit)        
            };
            
            fetch(`${config.apiUrl}`+"apimain/users",requestOptions)
                .then(response => {
                    this.setState({loaderModel:false});
                    if (response.status > 400) {
                        if(response.status==401){
                            Authentication.logout()
                        }
                        Swal.fire('Error!','Error While Processing','error');
                    }
                    return response.json();
            })
            .then(data => {
                this.setState({loaderModel:false});
                if(data!=undefined){
                    if("Success" in data){
                        Swal.fire('Success!','Changes are saved successfully!','success')
                        const headers = Object.keys(this.state.formDataEdit);
                        const { formDataEdit } = this.state
                        headers.map(header => formDataEdit[header]="")
                        this.setState({ formDataEdit });
                        this.fetchUsersData();    
                    }else{
                        this.setState({loaderModel:false});
                        Swal.fire({icon: 'error',title: 'Error',text: data["Errors"]})
                    }
                }
            });
        });
        this.setState({loaderModel:false});
    }

    handleSubmitNew=()=>{
        this.setState({loaderModel:true});
        this.setState({ submittednew: true }, () => {
            setTimeout(() => this.setState({ submittednew: false }), 5000);
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', Authorization:'JWT '+ this.state.authUser.accessToeken },
                body: JSON.stringify(this.state.formDataNew)        
            };
            
            fetch(`${config.apiUrl}`+"apimain/users",requestOptions)
            .then(response => {
                this.setState({loaderModel:false});
                if (response.status > 400) {
                    if(response.status==401){
                        Authentication.logout()
                    }
                    Swal.fire('Error!','Error While Processing','error');
                }
                return response.json();
            })
            .then(data => {
                this.setState({loaderModel:false});
                if(data!=undefined){
                    if("Success" in data){
                        Swal.fire('Success!','Changes are saved successfully!','success')
                        const headers = Object.keys(this.state.formDataEdit);
                        const { formDataEdit } = this.state
                        headers.map(header => formDataEdit[header]="")
                        this.setState({ formDataEdit });
                        this.fetchUsersData();    
                    }else{
                        Swal.fire({icon: 'error',title: 'Error',text: data["Errors"]})
                    }
                }
            })
            .catch((error) => {
                Swal.fire({icon: 'error',title: 'Error',text: error})
            });
            this.setState({loaderModel:false});
        });
        this.setState({loaderModel:false});
    }

    fetchUsersData(){
        this.setState({loaderModel:true});
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', Authorization:'JWT '+ this.state.authUser.accessToeken },        
        };
        //console.log("apiamara/customercards?page="+pagenumber);
        fetch(`${config.apiUrl}`+"apimain/users",requestOptions)
            .then(response => {
                this.setState({loaderModel:false});
                if (response.status > 400) {
                    if(response.status==401){
                        Authentication.logout()
                    }
                    Swal.fire('Error!','Error While Processing','error');
                }
                return response.json();
        })
        .then(data => {
            this.setState({loaderModel:false});
            if(data!=undefined){
                if("Success" in data){
                    this.setState({usersList:data.data})  
                }else{
                    Swal.fire({icon: 'error',title: 'Error',text: data["Errors"]})
                }
            }
        });
    }    

    componentDidMount() {
        if(this.state.authUser!=null){
            this.setState({loaderModel:true});
            var current_time = Date.now() / 1000;
            
            if ( jwt(this.state.authUser.accessToeken).exp < current_time) {
                Authentication.logout();
            }

            this.fetchUsersData()
        }else{
            this.setState({loaderModel:false});
            Authentication.logout();
        }
    }

    handleChangePassword=()=>{
        this.setState({ submittedpass: true }, () => {
            setTimeout(() => this.setState({ submittedpass: false }), 5000);
            if(this.state.newPassword==""){
                Swal.fire({icon: 'error',title: 'Error',text: 'Enter new password!'});
                return;
            }
            if(this.state.repeatPassword==""){
                Swal.fire({icon: 'error',title: 'Error',text: 'Enter new confirmation password!'});
                return;
            }
            if(this.state.repeatPassword!=this.state.newPassword){
                Swal.fire({icon: 'error',title: 'Error',text: 'New password and confirmation password in not matching!'});
                return;
            }
            
            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json', Authorization:'JWT '+this.state.authUser.accessToeken },        
                body:JSON.stringify({'password': this.state.newPassword,"employeeId":this.state.selectedEmployeeId})
            };
            
            fetch(`${config.apiUrl}`+"apimain/resetadminpass",requestOptions)
                .then(response => {
                if (response.status > 400) {
                    Swal.fire({icon: 'error',title: 'Error',text: 'Error while processing request!'});
                }
                return response.json();
            })
            .then(data => {
                this.setState({loaderModel:false});
                if(data!=undefined){
                    if("Success" in data){
                        Swal.fire('Success!','Password has been changed successfully!','success')
                        this.setState({newPassword:"",selectedEmployeeId:"",repeatPassword:"",passwordChange:false})
                    }else{
                        Swal.fire({icon: 'error',title: 'Error',text: data["Errors"]})
                    }
                }
            });
        })
        
    }

    handleChange =(event)=>{
        this.setState({[event.target.name] : event.target.value});
    }

    handleClearNew=()=>{   
        const { formDataNew } = this.state;
        const headers = Object.keys(this.state.formDataNew);
        headers.map(header => formDataNew[header]="")
        this.setState({ formDataNew });
    }

    handleClearEdit=()=>{   
        const { formDataEdit } = this.state;
        const headers = Object.keys(this.state.formDataEdit);
        headers.map(header => formDataEdit[header]="")
        this.setState({ formDataEdit });
    }

    render() {
        const inputStyle = {style:{fontSize:14,fontfamily:"Open Sans",marginTop:"15px"}}
        const inputStyleFirst = {style:{fontSize:14,fontfamily:"Open Sans"}}

        const inputLabelStyle = {style:{fontSize:14,fontfamily:"Open Sans"}}
        
        const { formData, submitted,submittednew,submittedpass } = this.state;
        const color = "#f50057";
        const loading = true;
        
        return (
            <React.Fragment>
                
                <Modal
                    open={this.state.passwordChange}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    style={{display:'flex',alignItems:'center',justifyContent:'center'}} >
                        
                        <div className="card">
                            <div className="card-body text-center">
                                <h4 className="mb-4">Change Password</h4>
                                <ValidatorForm style={{width:"100%",padding:"0 0 15px 0"}} onSubmit={(e)=>{handleSubmit(e)}}>
                                    
                                    <TextValidator InputLabelProps={inputStyle} InputProps={inputStyle} variant="outlined" size="small"
                                        label="Password"
                                        onChange={(e)=>this.handleChange(e)}
                                        value={this.state.newPassword}
                                        name="newPassword"
                                        type="password"
                                        validators={['required','minStringLength:6']}
                                        errorMessages={['this field is required','password should have atleast 6 charecters']}
                                        fullWidth
                                    />
                                    
                                    <TextValidator InputLabelProps={inputStyle} InputProps={inputStyle} variant="outlined" size="small" label="Repeat password"
                                        onChange={(e)=>this.handleChange(e)} name="repeatPassword" type="password"
                                        validators={['isPasswordMatch', 'required']} value={this.state.repeatPassword}
                                        errorMessages={['password mismatch', 'this field is required']}
                                        fullWidth
                                    />
                                    
                                    <Button color="secondary" variant="contained" type="submit" onClick={this.handleChangePassword} disabled={this.state.submittednew} style={{marginTop:"20px"}}>
                                        { (submittedpass && 'Loading..!') || (!submittedpass && 'Submit')} 
                                    </Button>
                                    <input style={{marginTop:"15px"}} onClick={()=>this.setState({passwordChange:false,newPassword:"",repeatPassword:""})} type="reset" className="btn btn-secondary m-l-10" value="Close"/>
                                </ValidatorForm>
                            </div>
                        </div>

                        {/* <BeatLoader color={color} loading={loading} size={17} /> */}
                </Modal>

                <Modal
                    open={this.state.loaderModel}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    style={{display:'flex',alignItems:'center',justifyContent:'center'}} >
                        
                    <BeatLoader color={color} loading={loading} size={17} />
                </Modal>
                
                <div className="col-xl-12 col-md-12 m-b-30" style={{top:"25px"}}>
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active show tablink" id="users-tab" data-toggle="tab" href="#users" role="tab" aria-controls="home" aria-selected="false">Users List<span className="navSuffix">{this.state.usersList.length}</span></a>
                        </li>
                        <li className="nav-item ">
                            <a className="nav-link tablink" id="edituser-tab" data-toggle="tab" href="#edituser" role="tab" aria-controls="profile" aria-selected="true">Edit</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link tablink" id="adduser-tab" data-toggle="tab" href="#adduser" role="tab" aria-controls="contact" aria-selected="false">Add User</a>
                        </li>
                    </ul>
                    
                    <div className="tab-content" id="myTabContent">
                        
                        <div className="tab-pane fade active show" id="users" role="tabpanel" aria-labelledby="users-tab">
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th>Sno</th>
                                        <th>Employee Id</th>
                                        <th>Full Name</th>
                                        <th>Preferred Name</th>
                                        <th>Access Role</th>
                                        <th>Status</th>
                                        <th>Reset</th>
                                        <th>Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.usersList.map((user,index) => {
                                        return (
                                            <tr key={index}>
                                                <td><h6>{index+1}</h6></td>
                                                <td><h6>{user.EmployeeId}</h6></td>
                                                <td><h6>{user.FirstName +" "+user.LastName}</h6></td>
                                                <td><h6>{user.PreferredName}</h6></td>
                                                <td>
                                                    {user.Role==2 && <h6>Admin</h6>}
                                                    {user.Role==3 && <h6>Doctor</h6>}
                                                    {user.Role==4 && <h6>Nurse</h6>}
                                                </td>
                                                <td><h6 className="text-center">{user.IsActive="True"?"Active":"Inactive"}</h6></td>
                                                <td><a href="#!" onClick={(e)=>{e.preventDefault(),this.setState({passwordChange:true,selectedEmployeeId:user.EmployeeId})}} className="label theme-bg2 text-white f-12">Reset</a></td>
                                                <td><a href="#!" onClick={(e)=>{e.preventDefault(),this.deleteUser(user.EmployeeId)}} className="label theme-bg2 text-white f-12">Delete</a></td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>

                        </div>
                        <div className="tab-pane fade" id="edituser" role="tabpanel" aria-labelledby="edituser-tab">
                            <div className="row" >
                                <h5>&nbsp;&nbsp;&nbsp;&nbsp; Edit User</h5> 
                                <ValidatorForm style={{width:"100%",padding:"0"}} ref="form" onSubmit={this.handleSubmitEdit}>
                                    <Grid container>
                                        <Grid item xs={6} style={{padding:"20px"}}>
                                            <Autocomplete
                                                value={this.state.formDataEdit.EmployeeId}
                                                name="EmployeeId"
                                                freeSolo
                                                onChange={this.onStaffIdSelect}                                            
                                                size="small"
                                                options={this.state.autoCompleteList.map((option) => option.EmployeeId)}
                                                renderInput={(params) => (params.inputProps.style={fontSize:"14px"},
                                                <TextField  onChange={this.handleChangeStaffId} {...params} InputLabelProps={inputLabelStyle} label="Employee Id" variant="outlined" />
                                                )}
                                            />
                                            <TextValidator InputLabelProps={inputStyle} InputProps={inputStyle} variant="outlined" size="small" label='First Name'
                                                onChange={this.handleEditChange} name="FirstName" value={this.state.formDataEdit.FirstName}
                                                validators={['required']} errorMessages={['this field is require']} autoComplete='off' fullWidth>
                                            </TextValidator>
                                            <FormControl style={{marginTop:"15px"}} variant="outlined" fullWidth size="small">
                                                <InputLabel style={{fontSize:14,fontfamily:"Open Sans"}} htmlFor="outlined-cardtype" size="small">Role</InputLabel>
                                                <Select style={{fontSize:14,fontfamily:"Open Sans"}} native   value={this.state.formDataEdit.Role} onChange={this.handleEditChange} label="Role" size="small"
                                                    inputProps={{
                                                        name: 'Role',
                                                        id: 'role-cardtype',
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />
                                                    <option value="1">Admin</option>
                                                    <option value="3">Doctor</option>
                                                    <option value="4">Nurse</option>   
                                                    
                                                </Select>
                                            </FormControl>
                                            <Button style={{marginTop:"15px"}}
                                                color="secondary"
                                                variant="contained"
                                                type="submit"
                                                disabled={submitted}
                                            >
                                                {
                                                    (submitted && 'Your form is submitted!')
                                                    || (!submitted && 'Submit')
                                                }
                                            </Button>
                                            <input style={{margin:"15px 15px 0 5px"}} onClick={this.handleClearEdit} type="reset" className="btn btn-secondary" value="Clear"/>
                                        </Grid>
                                        <Grid item xs={6} style={{padding:"20px"}}>
                                            <TextValidator InputLabelProps={inputStyleFirst} InputProps={inputStyleFirst} variant="outlined" size="small" label='Preferred Name'
                                                onChange={this.handleEditChange} name="PreferredName" value={this.state.formDataEdit.PreferredName}
                                                validators={['required']} errorMessages={['this field is require']} autoComplete='off' fullWidth>
                                            </TextValidator>  
                                            <TextValidator InputLabelProps={inputStyle} InputProps={inputStyle} variant="outlined" size="small" label='Last Name'
                                                onChange={this.handleEditChange} name="LastName" value={this.state.formDataEdit.LastName}
                                                validators={['required']} errorMessages={['this field is require']} autoComplete='off' fullWidth>
                                            </TextValidator>
                                            <TextValidator InputLabelProps={inputStyle} InputProps={inputStyle} variant="outlined" size="small" label='Email'
                                                onChange={this.handleEditChange} name="Email" value={this.state.formDataEdit.Email}
                                                validators={['required']} errorMessages={['this field is require']} autoComplete='off' fullWidth>
                                            </TextValidator>                                  
                                        </Grid>
                                    </Grid>
                                </ValidatorForm>
                            
                            </div>
                        </div>
                        <div className="tab-pane fade" id="adduser" role="tabpanel" aria-labelledby="adduser-tab">
                            <div className="row" >
                                <h5>&nbsp;&nbsp;&nbsp;&nbsp; Add User</h5> 
                                <ValidatorForm style={{width:"100%",padding:"0"}} ref="form" onSubmit={this.handleSubmitNew}>
                                    <Grid container>
                                        <Grid item xs={6} style={{padding:"20px"}}>
                                            <TextValidator InputLabelProps={inputStyleFirst} InputProps={inputStyleFirst} variant="outlined" size="small" label='Employee Id'
                                                onChange={this.handleChangeNew} name="EmployeeId" value={this.state.formDataNew.EmployeeId}
                                                validators={['required']} errorMessages={['this field is require']} autoComplete='off' fullWidth>
                                            </TextValidator>
                                            <TextValidator InputLabelProps={inputStyle} InputProps={inputStyle} variant="outlined" size="small" label='First Name'
                                                onChange={this.handleChangeNew} name="FirstName" value={this.state.formDataNew.FirstName}
                                                validators={['required']} errorMessages={['this field is require']} autoComplete='off' fullWidth>
                                            </TextValidator>
                                            <FormControl style={{marginTop:"15px"}} variant="outlined" fullWidth size="small">
                                                <InputLabel style={{fontSize:14,fontfamily:"Open Sans"}} htmlFor="outlined-cardtype" size="small">Role</InputLabel>
                                                <Select style={{fontSize:14,fontfamily:"Open Sans"}} native   value={this.state.formDataNew.Role} onChange={this.handleChangeNew} label="Role" size="small"
                                                    inputProps={{
                                                        name: 'Role',
                                                        id: 'role-cardtype',
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />
                                                    <option value="2">Admin</option>
                                                    <option value="3">Doctor</option>
                                                    <option value="4">Nurse</option>   
                                                    
                                                </Select>
                                            </FormControl>
                                            <Button style={{marginTop:"15px"}}
                                                color="secondary"
                                                variant="contained"
                                                type="submit"
                                                disabled={submittednew}
                                            >
                                                {
                                                    (submittednew && 'Your form is submitted!')
                                                    || (!submittednew && 'Submit')
                                                }
                                            </Button>
                                            <input style={{margin:"15px 15px 0 5px"}} onClick={this.handleClearNew} type="reset" className="btn btn-secondary" value="Clear"/>
                                        </Grid>
                                        <Grid item xs={6} style={{padding:"20px"}}>
                                            <TextValidator InputLabelProps={inputStyleFirst} InputProps={inputStyleFirst} variant="outlined" size="small" label='Preferred Name'
                                                onChange={this.handleChangeNew} name="PreferredName" value={this.state.formDataNew.PreferredName}
                                                validators={['required']} errorMessages={['this field is require']} autoComplete='off' fullWidth>
                                            </TextValidator>  
                                            <TextValidator InputLabelProps={inputStyle} InputProps={inputStyle} variant="outlined" size="small" label='Last Name'
                                                onChange={this.handleChangeNew} name="LastName" value={this.state.formDataNew.LastName}
                                                validators={['required']} errorMessages={['this field is require']} autoComplete='off' fullWidth>
                                            </TextValidator>
                                            <TextValidator InputLabelProps={inputStyle} InputProps={inputStyle} variant="outlined" size="small" label='Email'
                                                onChange={this.handleChangeNew} name="Email" value={this.state.formDataNew.Email}
                                                validators={['required']} errorMessages={['this field is require']} autoComplete='off' fullWidth>
                                            </TextValidator>                                  
                                        </Grid>
                                    </Grid>
                                </ValidatorForm>
                            
                            </div>
                        </div>
 
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Users