import React, { useEffect, useState } from "react"
import {
  Container,
  Grid,
  TextField,
  Select,
  MenuItem,
  makeStyles,
  Button,
} from "@material-ui/core"
import {
  TextFieldWrapper,
  DateFieldWrapper,
  NumberFieldWrapper,
  SelectFieldWrapper,
} from "./Elements"

const useSelectStyles = makeStyles(theme => ({
  root: {
    width: 130,
  },
}))

const useStyles = makeStyles(theme => ({
  borderTable1: {
    border: "1px solid #d1d1d1",
    margin: "0",
    marginTop:"5px",
    padding: theme.spacing(2),
    backgroundColor:"#fff",
    borderRadius:"5px",
    boxShadow:"0 3px 10px 0 rgb(0 0 0 / 5%)"
  },
  tableStyles: {
    padding: "0 0 0 15px"
  },
  table2Styles: {
    border: "1px solid",
  },
  labelWidth: {
    display: "inline-block",
    width: "30%",
    fontWeight: "bold",
  },
  typeWidth: {
    margin: theme.spacing(5),
    "& .MuiFormControl-root": {
      width: "45%",
    },
  },
  verticalAlign: {
    verticalAlign: "baseline",
  },
}))

const data = [
  { label: "Presenting  Complaints", name:"PrsentingComplaints", type: "text", size: 12, tableType: 3,value:"" },
  {label: "Obstetrics/Menstrual History",name:"ObstectionHist", type: "text",size: 12,tableType: 3,value:""},
  { label: "Present Medications",name:"PresentMedications", type: "text", size: 12, tableType: 3,value:"" },
  { label: "Allergic To", name:"AllergicTo", type: "text", size: 12, tableType: 3 ,value:""},
]

const TableThree = props => {
  const classes = useStyles()
  const SelectStyles = useSelectStyles()

  const [fieldData, setFieldData]=useState(data)
  const [isFemale,setIsFemale]=useState(false)

  const Item = props => {
    var tableStyles = classes.tableStyles
    const { border, size, pageBreak } = props
    var table2Styles = border ? classes.table2Styles : ""

    return (
      <>
        <Grid item sm={size} className={tableStyles + " " + table2Styles}>
          <Element {...props} />
        </Grid>
      </>
    )
  }

  const Element = props => {
    const { label, type, width,name,value } = props

    var verticalAlign = classes.verticalAlign
    var typeWidth = classes.typeWidth
    var labelWidth = classes.labelWidth
    var disableTextfield = name=="ObstectionHist"? !isFemale:false;
    

    if (["select"].includes(type)) {
      const { list } = props
      return (
        <>
          <span className={labelWidth}> {label}</span> :
          <span className={typeWidth}>
            <SelectFieldWrapper
              classes={SelectStyles.root}
              list={list}
              label={label}
              value={value}
              name={name}
            />
          </span>
        </>
      )
    }
    if (["date"].includes(type)) {
      return (
        <>
          <span className={labelWidth}> {label}</span> :
          <span className={typeWidth}>
            <DateFieldWrapper classes={SelectStyles.root} label={label} name={name} value={value} />
          </span>
        </>
      )
    }
    if (["text"].includes(type)) {
      return (
        <div style={{maxHeight:"25px"}}>
          <span className={labelWidth}> {label}</span> :
          <span className={typeWidth}>
            <TextFieldWrapper
              width={width}
              classes={verticalAlign}
              label={label} name={name} value={value} disable={disableTextfield}
            />
          </span>
        </div>
      )
    }
    if (["number"].includes(type)) {
      return (
        <>
          <span className={labelWidth}> {label}</span> :
          <span className={typeWidth}>
            <NumberFieldWrapper type={type} label={label} name={name} value={value}/>
          </span>
        </>
      )
    }
  }


  useEffect(() => {
    if(props.data!=undefined && props.data!={}){
      var dataFromDb = []
      fieldData.map((field,index)=>{
        field["value"] = props.data[field["name"]]==undefined?"":props.data[field["name"]]
        dataFromDb.push(field)
      })
      setFieldData(dataFromDb)
    }
  
  }, [props.data])

  useEffect(()=>{
    setIsFemale(props.isFemale)
  },[props.isFemale])

  return (
    <Container className={classes.borderTable1}>
      <Grid container>
        {fieldData.map((eachfield, index) => {
          const prop = {
            ...eachfield
          };
          return <Item {...prop} key={index} />
        })}
      </Grid>
    </Container>
  )
}

export default TableThree
