import React, { useState, useEffect, useDebugValue } from "react";
import { Button, Container, makeStyles, Grid } from "@material-ui/core";
import TableOne from "./TableOne";
import TableTwo from "./TableTwo";
import TableThree from "./TableThree";
import TableFour from "./TableFour";
import TableFive from "./TableFive";
import TableSixth from "./TableSixth";
import TableSeventh from "./TableSeventh";
import TableEighth from "./TableEighth";
import TableNinth from "./TableNinth";
import config from "config";
import Swal from "sweetalert2";
import BeatLoader from "react-spinners/BeatLoader";
import Modal from "@material-ui/core/Modal";
import Disclaimer from "./Disclaimer";
import { TextField, Select, MenuItem, Checkbox } from "@material-ui/core";
import { Authentication } from "../auth/Authenticatoin";
import { TextFieldWrapper } from "./Elements";

const useStyles = makeStyles((theme) => ({
  topSpace: {
    marginTop: 20,
  },
}));

const FormComponent = (props) => {
  const [topButtons, setTopButtons] = useState(props.topButtons || false);
  const [svBtnRight, setsvBtnRight] = useState(props.svBtnRight || "120px");
  const [bottomButtons, setBottomButtons] = useState(
    props.bottomButtons || false
  );
  const [selectedData, setSelectedData] = useState({});
  const [loaderModel, setLoaderModel] = useState(true);
  const [isFemale, setIsFemale] = useState(true);
  const classes = useStyles();
  const [isFSSI, setIsFSSI] = useState(false);
  const [consultation, setConsulation] = useState();
  const authUser = Authentication.authUserVlaue;

  const onGenderChange = (val) => {};

  useEffect(() => {
    setIsFSSI(selectedData["IsFSSI"]);
    setConsulation(selectedData["GeneralPhysicianConsultation"]);
  }, [selectedData]);

  const Body = () => {
    return (
      <div className="topMostDiv">
        {/* <div className="mb-4 unhide"  style={{textAlign:"right",display:"none" }}>
            <img style={{width:"70px"}} src="../static/images/favicon.png"/>
        </div> */}
        {/*  ist table */}
        <TableOne data={selectedData} onGenderChange={onGenderChange} />
        {/*  2nd table */}
        <TableTwo data={selectedData} />
        {/*  3rd table */}
        <TableThree data={selectedData} isFemale={isFemale} />
        {/* 4th table */}
        <TableFour data={selectedData} />
        {/*  5th table */}
        <TableFive data={selectedData} />

        {/* <div className="mb-4 unhide" style={{textAlign:"right",pageBreakBefore: "always",display:"none" }}>
            <img style={{width:"70px"}} src="../static/images/favicon.png"/>
        </div> */}
        {/*  6th table */}
        <TableSixth data={selectedData} />
        {/* 7th table */}
        <TableSeventh data={selectedData} />
        {/*  8th table */}
        <TableEighth data={selectedData} />
        {/*  9th table */}
        <TableNinth
          data={selectedData}
          consultation={consultation}
          setConsulation={(e) => setConsulation(e)}
        />
        <div
          className="m-1 hide"
          style={{
            textAlign: "center",
            padding: "10px",
            backgroundColor: "white",
            border: "1px solid #d1d1d1",
            boxShadow: "0 3px 10px 0 rgb(0 0 0 / 5%)",
          }}
        >
          <p style={{ marginBottom: 0 }}>Is Food Industry?</p>
          <Checkbox
            name="IsFSSI"
            onChange={(e) => {

              setIsFSSI(e.target.checked);
            }}
            checked={isFSSI === true ? true : false}
            color="secondary"
          />
        </div>

        {isFSSI && (
          <>
            {/* <div className="mb-4 unhide" style={{textAlign:"right",pageBreakBefore: "always",display:"none" }}>
              <img style={{width:"70px"}} src="../static/images/favicon.png"/>
            </div> */}
            <Disclaimer data={selectedData} />
          </>
        )}
      </div>
    );
  };
  const Footer = () => {
    return (
      //  this id refers to footer group
      <Container className={`${classes.topSpace} hide topbuttons`}>
        <Grid container justifyContent="flex-end" spacing={1}>
          <Grid
            item
            style={{ position: "fixed", right: svBtnRight, zIndex: "1000" }}
          >
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              name="Save"
            >
              Save
            </Button>
          </Grid>
          {/* <Grid item>
            <Button type='submit' variant='contained' color='secondary' name="Save">
              Submit
            </Button>
          </Grid> */}
          <Grid item style={{ position: "fixed", zIndex: "1000" }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                document.title = selectedData.MRNNumber
                  ? `${selectedData.Name}-${selectedData.EmployeeId}-${selectedData.MRNNumber}`
                  : "File";

                setTimeout(() => {
                  window.print();

                }, 100);
              }}
            
            >
              Print
            </Button>
          </Grid>
        </Grid>
      </Container>
    );
  };
  const beforePrint = () => {
    for (let el of document.querySelectorAll(".hide"))
      el.style.display = "none";

    for (let el of document.querySelectorAll(".unhide"))
      el.style.display = "block";

    for (let el of document.querySelectorAll(".searchBtn"))
      el.style.display = "none";

    for (let el of document.querySelectorAll(".pcoded-main-container"))
      el.style.marginLeft = "15px";

    for (let el of document.querySelectorAll(".pcoded-inner-content"))
      el.style.paddingTop = "10px";

    for (let el of document.querySelectorAll(".topMostDiv"))
      el.style.paddingTop = "100px";

    for (let el of document.querySelectorAll(".divForSearch"))
      el.style.paddingTop = "20px";
  };
  const afterPrint = () => {
    for (let el of document.querySelectorAll(".hide"))
      el.style.display = "block";
    for (let el of document.querySelectorAll(".unhide"))
      el.style.display = "none";
    for (let el of document.querySelectorAll(".searchBtn"))
      el.style.display = "flex";
    for (let el of document.querySelectorAll(".topMostDiv"))
      el.style.paddingTop = "20px";
    for (let el of document.querySelectorAll(".divForSearch"))
      el.style.paddingTop = "20px";
  };


  //  every time when we we update state whole component re-render so we need to attach event listerener
  useEffect(() => {
    window.onbeforeprint = beforePrint;
    window.onafterprint = afterPrint;
    setSelectedData(props.action == "new" ? {} : props.data);
    setLoaderModel(false);
  }, [props.data]);

  const createNewEmp = (data) => {
    const requestOptions = {
      method: props.action == "edit" ? "PUT" : "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + authUser.accessToeken,
      },
      body: JSON.stringify(data),
    };

    // setLoaderModel(true)
    fetch(`${config.apiUrl}` + "apimain/employee", requestOptions)
      .then((response) => {
        if (response.status > 400) {
          // if(response.status==401){
          //     Authentication.logout()
          // }
          Swal.fire("Error!", "Error While Processing", "error");
        }
        // setLoaderModel(false)
        return response.json();
      })
      .then((data) => {
        if (data != undefined) {
          if ("Success" in data) {
            // setLoaderModel(false)
            Swal.fire("Success!", data["Errors"], "success");
          }
          if ("Fail" in data) {
            var errors = "";
            data["Errors"].map((x) => (errors += x));
            // setLoaderModel(false)
            Swal.fire("Error!", errors, "error");
          }
        }
      });
  };

  const color = "#f50057";
  const loading = true;
  return (
    <React.Fragment>
      <Modal
        open={loaderModel}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <BeatLoader color={color} loading={loading} size={17} />
      </Modal>
      <form
        id="formTable"
        onSubmit={(e) => {
          e.preventDefault();
          var allElements = e.target;
          var values = Object.values(allElements);
          var data = {};

          values.map((eachElement) => {
            if (eachElement !== "") {
              if (
                [
                  "text",
                  "date",
                  "number",
                  "checkbox",
                  "datetime-local",
                ].includes(eachElement.type)
              ) {
                const { name } = eachElement;
                if (eachElement.type === "checkbox") {
                  const { checked } = eachElement;
                  data[name] = checked;
                } else {
                  const { value } = eachElement;
                  data[name] = value;
                }
              }
            }
          });

          createNewEmp(data);
        }}
      >
        {topButtons && <Footer />}
        <Body />
        {/* {bottomButtons && <Footer />} */}
      </form>
    </React.Fragment>
  );
};
export default FormComponent;
