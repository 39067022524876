import React, { useState, useEffect } from "react"
import {
  Container,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
} from "@material-ui/core"
// import YearPicker from "react-year-picker"
import Dashboard from "../Dashboard"
import Swal from "sweetalert2"
import config from 'config'
import Reports from "../reports/Index"
import BeatLoader from "react-spinners/BeatLoader";
import Modal from '@material-ui/core/Modal';
import { Authentication } from "../auth/Authenticatoin"

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 130,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },
}))

const Search = props => {
  const classes = useStyles()
  const year = new Date().getFullYear()
  const [selectedYear, handleYearChange] = useState(year)
  const [mRNNumber, handleMRNChange] = useState("")
  const [employeeId, handleEmpIdChange] = useState("")
  const [dataFromApi,setDataFromApi] = useState({})
  const [isNewDataGot,setisNewDataGot] = useState(false)
  const [isReportsData,setisReportsData]= useState(true)
  const [reportsData,setReportsData] = useState({Hematology:{},Biochemistry:{},ClinicalPathalogy:{},MicroBiology:{}})
  const [loaderModel,setLoaderModel] = useState(false)
  const authUser=Authentication.authUserVlaue;
  const yearLength = 5

  const handleSearch=()=>{

    if(employeeId=="" && mRNNumber==""){
      Swal.fire("Error!","Enter employee Id","error")
      return;
    }
   
    const requestOptions = {
      method: 'GET',
      // headers: { 'Content-Type': 'application/json'}
      headers: { 'Content-Type': 'application/json',"Authorization":"JWT "+authUser.accessToeken}
    };
    var url = `${config.apiUrl}`+"apimain/employee?EmpId="+employeeId+"&Year="+selectedYear
    
    if(employeeId=="" && mRNNumber!=""){
      url = `${config.apiUrl}`+"apimain/employee?MRN="+mRNNumber+"&Year="+selectedYear
    }
    
    setLoaderModel(true)

    fetch(url,requestOptions)
    .then(response => {
        if (response.status > 400) {
            // if(response.status==401){
            //     Authentication.logout()
            // }
            Swal.fire('Error!','Error While Processing','error');
        }
        setLoaderModel(false)
        return response.json();
    })
    .then(data => {
        setLoaderModel(false)
        if(data!=undefined){
            if("Success" in data){
              if(data.data.length!=0){
                setDataFromApi(data.data[0])
                
                getReportsData()
              }else{
                
                setisNewDataGot(false)
                setisReportsData(false)
                Swal.fire('Error!','No data found.','error');
              }
            }
            if("Fail" in data){
                var errors =""
                data["Errors"].map(x=>errors+=x)
                Swal.fire('Error!',errors,'error');
            }
        }
    });
  } 

  const handleClear=()=>{
    setDataFromApi({})
    setisNewDataGot(false)
    setisReportsData(false)
    setReportsData({Hematology:{},Biochemistry:{},ClinicalPathalogy:{},MicroBiology:{}})
    handleEmpIdChange("")
    handleMRNChange("")
  }

  const getReportsData=()=>{

    var initialValues={EmpId:employeeId,Year:selectedYear,MRN:mRNNumber}
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json',"Authorization":"JWT "+authUser.accessToeken},
      body: JSON.stringify(initialValues)   
    };
    var url = `${config.apiUrl}`+"apimain/reports"
    setLoaderModel(true)
    fetch(url,requestOptions)
    .then(response => {
        setLoaderModel(false)
        if (response.status > 400) {
            // if(response.status==401){
            //     Authentication.logout()
            // }
            Swal.fire('Error!','Error While Processing','error');
        }
        return response.json();
    })
    .then(data => {
      setLoaderModel(false)
        if(data!=undefined){
            if("Success" in data){
              
              setReportsData(data)
            }
            if("Fail" in data){
                var errors =""
                data["Errors"].map(x=>errors+=x)
                
            }
        }
    });
  }

  useEffect(()=>{
    if (reportsData.Biochemistry.length>0 || reportsData.ClinicalPathalogy.length>0 || reportsData.Hematology.length>0 || reportsData.MicroBiology.length>0){
      setisReportsData(true)
    }else{
      setisReportsData(false)
    }
  }, [reportsData])

  useEffect(() => {
    
    if(Object.keys(dataFromApi).length != 0){
      
      setisNewDataGot(true)
    }
  }, [dataFromApi]);

  return (
    <>
      <Modal
        open={loaderModel}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{display:'flex',alignItems:'center',justifyContent:'center'}} >
            
        <BeatLoader color="#f50057" loading={true} size={17} />
      </Modal>
    <Container className="divForSearch" style={{paddingTop:"20px"}}>
        <Grid container className="searchBtn" alignItems='center' style={{padding:"10px",backgroundColor:"#fff"}}>
          <Grid item xs={3}>
            <FormControl className={classes.formControl}>
              <InputLabel id='demo-simple-select-label'>
                Present Year
              </InputLabel>
              <Select value={selectedYear} onChange={(e)=>handleYearChange(e.target.value)}>
                {Array(yearLength)
                  .fill(0)
                  .map((value, index) => {
                    return (
                      <MenuItem value={year + yearLength - index}>
                        {year + yearLength - index}
                      </MenuItem>
                    )
                  })}
                <MenuItem value={year}>{year}</MenuItem>
                {Array(yearLength)
                  .fill(0)
                  .map((value, index) => {
                    return (
                      <MenuItem value={year - (index + 1)}>
                        {year - (index + 1)}
                      </MenuItem>
                    )
                  })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <TextField value={mRNNumber} onChange={(e)=>handleMRNChange(e.target.value)} id='standard-basic' label='MRN NO' type='text' />
          </Grid>
          <Grid item xs={3}>
            <TextField value={employeeId} onChange={(e)=>handleEmpIdChange(e.target.value)} id='standard-basic' label='EMPLOYEE ID' type='text' />
          </Grid>
          <Grid item xs={3}>
            <Button onClick={()=>handleSearch()} variant='contained' color='secondary'>
              Search
            </Button>
            <Button style={{marginLeft:"10px"}} onClick={()=>handleClear()} variant='contained' color='secondary'>
              Clear
            </Button>
            
          </Grid>
        </Grid>
        
        {isNewDataGot==true && <Dashboard svBtnRight="150px" topButtons={true} bottomButtons={true} data={dataFromApi} action="edit"/>}
        {isReportsData ==true &&  <Reports data={reportsData} empData={dataFromApi}/>}

    </Container>
    </>
    
  )
}

export default Search
