import React from 'react';
import {
    Container,
    Grid, 
    makeStyles,
    Typography,
    FormControlLabel,
  } from "@material-ui/core"
import { CheckboxWrapper } from "./Elements"
import moment from 'moment';  

const useStyles = makeStyles(theme => ({
    borderTable: {
      border: "none",
      margin: "15px 0px",
      padding: theme.spacing(2),
      backgroundColor:"#fff",
      borderRadius:"5px",
      boxShadow:"0 3px 10px 0 rgb(0 0 0 / 5%)"
    },
    tableStyles: {
      padding: "3px 0 0 15px",
    },
    labelWidth: {
      display: "inline-block",
      width: 300,
      fontWeight: "bold",
    },
    verticalAlign: {
      verticalAlign: "baseline",
      padding:"0"
    },
  }))
  

export default function(props){
    const {Name,CompName} = props.data
    const classes = useStyles()
    var tableStyles = classes.tableStyles

    return<>
     <Container className={classes.borderTable} style={{ pageBreakBefore: "always",paddingTop:"100px"}}>
        <Grid container>
            <Grid item sm={12} style={{textAlign:"center"}}>
                <p style={{fontSize:"17px",fontWeight:"800",marginBottom:"0"}}> PERFORMA FOR MEDICAL FITNESS CERTIFICATION FOR FOOD HANDLERS</p>
            </Grid>
            <Grid item sm={12} style={{textAlign:"center",marginBottom:"0"}}>
                <p style={{marginBottom:"0",fontSize:"15px"}}> (FOR THE YEAR 2022)</p>
            </Grid>
            <Grid item sm={12} style={{textAlign:"center",marginBottom:"20px"}}>
                <p style={{fontSize:"15px"}}> (See Para No.10.1.2, Part-11, Schedule -4 of FSS Regulation, 2011)</p>
            </Grid>
            <Grid item sm={12} style={{textAlign:"center",marginBottom:"150px"}}>
                <p style={{padding:"0 25px 0 25px",textAlign:"justify",fontSize:"15px"}}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                    It is certified that Shri/Smt/Miss <b> <span id="disclaimerName">{Name||""}</span></b>   employed with
                    <b> <span id="disclaimerCompName">{CompName||""}</span>.</b> coming in direct contact with food items has
                    Been carefully examined by me on date <b>{moment().format("DD-MM-YYYY") }</b>. Based on the medical examination
                    Conducted, he/she is found free from any infectious or communicable diseases and the
                    Person is fit to work in the above mentioned food establishment.
                </p>
            </Grid>
            <Grid item sm={7} style={{textAlign:"center",marginBottom:"50px"}}>
                <p style={{padding:"0 25px 0 25px",textAlign:"justify",fontSize:"15px"}}>
                    
                </p>
            </Grid>
            <Grid item sm={5} style={{textAlign:"left",marginBottom:"50px"}}>
                <p style={{padding:"0 25px 0 25px",textAlign:"justify",fontSize:"15px"}}>
                <b>Name and Signature with Seal</b>
                <br/>Of Registered Medical Practitioner/
                <br/>Civil Surgeon

                </p>
            </Grid>
            <Grid item sm={12} style={{textAlign:"left",marginBottom:"10px"}}>
                <p style={{padding:"0 25px 0 25px",textAlign:"left",fontSize:"15px"}}>
                   
                  <b>*Medical Examination to be conducted:</b>
                  <br/>1. Physical Examination
                  <br/>2. Eye Test
                  <br/>3. Skin Examination
                  <br/>4. Compliance with schedule of Vaccine to be inoculated against enteric group of diseases
                  <br/>5. Any test required to confirm any communicable or infectious diseases which the person suspected to be suffering from on clinical examination

                </p>
            </Grid>
        </Grid>
        </Container>
        
       
    </>
}