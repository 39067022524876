import React, { useEffect, useState } from "react"
import { Container, Grid, makeStyles, TextField } from "@material-ui/core"
import WebcamCapture from "./Webcam"
import moment from "moment"

import {
  TextFieldWrapper,
  DateFieldWrapper,
  NumberFieldWrapper,
  SelectFieldWrapper,
  DateTimeFieldWrapper,
} from "./Elements"

const useSelectStyles = makeStyles(theme => ({
  root: {
    width: 200,
  },
}))

const useStyles = makeStyles(theme => ({
  borderTable: {
    border: "1px solid #d1d1d1",
    margin: "0 0px",
    padding: theme.spacing(1),
    backgroundColor:"#fff",
    borderRadius:"5px",
    boxShadow:"0 3px 10px 0 rgb(0 0 0 / 5%)"
  },
  tableStyles: {
    padding: "0 0 0 15px"
  },
  labelWidth: {
    display: "inline-block",
    width: 130,
    fontWeight: "bold",
  },
  typeWidth: {
    margin: theme.spacing(5),
  },
  verticalAlign: {
    verticalAlign: "baseline",
  },
}))

console.log('a')

const data = [
  { label: "Date", name:"DateOfEntry", type: "date", size: 6, tableType: 1 ,value:""},
  { label: "MRN Number *",name:"MRNNumber", type: "text", size: 6, tableType: 1,value:"" },
  { label: "Emp ID *", name:"EmployeeId", type: "text", size: 6, tableType: 1 ,value:""},
  { label: "Grade", name:"Grade",type: "text", size: 6, tableType: 1 ,value:""},
  { label: "Name", name:"Name", type: "text", size: 6, tableType: 1 ,value:""},
  { label: "Age *", name:"Age",type: "text", size: 6, tableType: 1 ,value:""},
  { label: "Department",name:"Department", type: "text", size: 6, tableType: 1 ,value:""},
  { label: "Gender *", name:"Sex",type: "select", list: ["MALE", "FEMALE"], size: 6,tableType: 1,value:""},
  { label: "Sub-Division", name:"SubDivision",type: "text", size: 6, tableType: 1 ,value:""},
  { label: "Work Experience as on",name:"Experience", type: "text", size: 6, tableType: 1 ,value:""},
  // { label: "Plant", name:"Plant", type: "text", size: 6, tableType: 1 ,value:""},
  
  { label: "Division", name:"Division",type: "text", size: 6, tableType: 1 ,value:""},
  // { label: "Comp Code",name:"CompanyCode", type: "select", size: 6, tableType: 1 ,value:"",list: ["0001", "0002"]},
  { label: "Company Name",name:"CompName", type: "select", size: 6, tableType: 1 ,value:"",list: ["AREL", "ARIPL", "ARPSL", "RFPL"]},
  { label: "Work Location",name:"Location", type: "text", size: 6, tableType: 1 ,value:""},
//  { label: "DOB", name:"DateOfBirth", type: "date", size: 6, tableType: 1 ,value:null}, { label: "DOJ", name:"DateOfJoining", type: "date", size: 6, tableType: 1 ,value:null},
  // { label: "Area",name:"Area", type: "text", size: 6, tableType: 1 ,value:""},
]

const TableOne = props => {
  const classes = useStyles()
  const SelectStyles = useSelectStyles()
  const snapsheetHeight = 5
  
  const Item = props => {
    var tableStyles = classes.tableStyles
    const { index } = props
    const onEntryDateChange=(val)=>{
      alert(val)
    }
    return (
      <>
        <Grid
          item
          sm={index < snapsheetHeight ? 12 : 6}
          className={tableStyles}
        >
          <Element {...props} onEntryDateChange={onEntryDateChange} />
        </Grid>
      </>
    )
  }

  const Element = props => {
    const { label, type,name,value,onEntryDateChange } = props
    var verticalAlign = classes.verticalAlign
    var typeWidth = classes.typeWidth
    var labelWidth = classes.labelWidth

    if (["select"].includes(type)) {
      
      const { list,onGenderChange,onEntryDateChange } = props;
      
      return (
        <>
          <span className={labelWidth}> {label}</span> :
          <span className={typeWidth}>
            <SelectFieldWrapper
              classes={SelectStyles.root}
              list={list}
              label={label}
              value={value.toUpperCase()}
              name={name}
              onGenderChange={onGenderChange}
            />
          </span>
        </>
      )
    }
    
    if (["date"].includes(type)) {
      
      return (
        <>
          <span className={labelWidth}> {label}</span> :
          <span className={typeWidth}>
            <TextFieldWrapper 
              classes={SelectStyles.root} 
              label={label} 
              name={name} 
              value={value!=="" ? moment(value).format("yyyy-MM-DD")  : moment(new Date()).format("yyyy-MM-DD")}
              // onEntryDateChange={onEntryDateChange}
              />
          </span>
        </>
      )
    }

    if (["datetime"].includes(type)) {
      return (
        <>
          <span className={labelWidth}> {label}</span> :
          <span className={typeWidth}>
            <DateTimeFieldWrapper classes={SelectStyles.root} label={label} name={name} 
            value={moment(value).format('yyyy-MM-DD HH:mm')} />
          </span>
        </>
      )
    }
    
    if (["text"].includes(type)) {

      return (
        <div style={{paddingTop: name=="Gender"?"5px":"0"}}>
          <span className={labelWidth}> {label}</span> :
          <span className={typeWidth}>
           
              <TextFieldWrapper label={label} name={name} classes={verticalAlign} value={value}/>
            
            
          </span>
        </div>
      )
    }

    if (["number"].includes(type)) {
      return (
        <>
          <span className={labelWidth}> {label}</span> :
          <span className={typeWidth}>
            {name!=="Age" && 
              <NumberFieldWrapper type={type} label={label} name={name} value={value}/>
            }
            
          </span>
        </>
      )
    }
  }

  const Snapshot = props => {

    const width = 300
    const height = 170
    return (
      <div
        style={{
          position: "absolute",
          fontWeight: "bold",
          color: "black",
          backgroundColor: "rgb(210 210 210)",
          width: width,
          height: height,
          right: 100,
          zIndex: 1,
        }}
      >
        <WebcamCapture width={width} height={height} value={props.value}/>
      </div>
    )
  }

  const [fieldData, setFieldData]=useState(data)
  const [imageValue, setImageValue]=useState("")

  useEffect(() => {
      if(props.data!=undefined && props.data!={}){
        var dataFromDb = []
        
        fieldData.map((field,index)=>{
          field["value"] = props.data[field["name"]]==undefined?"":props.data[field["name"]]
          dataFromDb.push(field)
        })
        setFieldData(dataFromDb)
        setImageValue(props.data["ImageValue"])
      }
    
  }, [props.data])

  return (
    <Container className={classes.borderTable}>
      <Snapshot value={imageValue}/>
      <Grid container>
        {fieldData.map((eachfield, index) => {
          const prop = { ...eachfield, index, onGenderChange:props.onGenderChange }
          
          return <Item {...prop} key={index} />
        })}
      </Grid>
      <input type="hidden" name="empDateOfJoin" id="empDateOfJoin" value={props.data["DateOfJoining"]!=undefined?moment(props.data["DateOfJoining"]).format("yyyy-MM-DD"):""}/>
      <input type="hidden" name="empDateOfBirth" id="empDateOfBirth" value={props.data["DateOfBirth"]!=undefined?moment(props.data["DateOfBirth"]).format("yyyy-MM-DD"):""}/>
    </Container>
  )
}

export default TableOne
