import React, { Component, useState, useEffect } from "react";
import { DateFieldWrapper } from "../Dashboard/Elements";
import { Container, Grid, makeStyles, TextField } from "@material-ui/core";
import moment from "moment";
import { Pagination } from "@mui/material";
import { Authentication } from "../auth/Authenticatoin";
import config from "config";

const useSelectStyles = makeStyles((theme) => ({
  root: {
    width: 200,
  },
}));

const useStyles = makeStyles((theme) => ({
  borderTable: {
    border: "1px solid #d1d1d1",
    margin: "0 0px",
    padding: theme.spacing(1),
    backgroundColor: "#fff",
    borderRadius: "5px",
    boxShadow: "0 3px 10px 0 rgb(0 0 0 / 5%)",
  },
  tableStyles: {
    padding: "0 0 0 15px",
  },
  labelWidth: {
    display: "inline-block",
    width: 80,
    fontWeight: "bold",
  },
  typeWidth: {
    margin: theme.spacing(1),
  },
  verticalAlign: {
    verticalAlign: "baseline",
  },
}));

const data = {
  Success: "True",
  total: 400,
  data: [
    {
      EmployeeId: "1017853",
      Name: "Puvvaram Lalitha",
      MRNNumber: "AH0000109275",
      Department: "Post Injection Moulding",
      Age: "30",
      Experience: "1",
      Location: "Petamitta",
      DateOfBirth: "1994-04-01",
      DateOfJoining: "2023-05-01",
    },
    {
      EmployeeId: "1017850",
      Name: "Manikandan. A",
      MRNNumber: "AH0000109271",
      Department: "Quality Assurance",
      Age: "27",
      Experience: "1",
      Location: "Petamitta",
      DateOfBirth: "1996-05-25",
      DateOfJoining: "2023-04-17",
    },
    {
      EmployeeId: "1017901",
      Name: "Madhavi Chinthamakula",
      MRNNumber: "AH0000109322",
      Department: "Post Injection Moulding",
      Age: "27",
      Experience: "3",
      Location: "Petamitta",
      DateOfBirth: "1997-03-22",
      DateOfJoining: "2023-10-01",
    },
    {
      EmployeeId: "1017833",
      Name: "Vepareddy Jyothiswar Reddy",
      MRNNumber: "AH0000109265",
      Department: "Maintenance",
      Age: "23",
      Experience: "1",
      Location: "Petamitta",
      DateOfBirth: "2000-12-01",
      DateOfJoining: "2023-03-10",
    },
    {
      EmployeeId: "1017827",
      Name: "Pallapolla Ruma",
      MRNNumber: "AH0000109256",
      Department: "Injection Molding",
      Age: "26",
      Experience: "1",
      Location: "Petamitta",
      DateOfBirth: "1997-08-10",
      DateOfJoining: "2023-03-01",
    },
    {
      EmployeeId: "1017913",
      Name: "Rajolla Dharani",
      MRNNumber: "AH0000109228",
      Department: "Injection Molding",
      Age: "31",
      Experience: "3",
      Location: "Petamitta",
      DateOfBirth: "1992-05-25",
      DateOfJoining: "2023-11-01",
    },
    {
      EmployeeId: "1017803",
      Name: "Pethapaneni Rekha",
      MRNNumber: "AH0000109249",
      Department: "Production",
      Age: "29",
      Experience: "1",
      Location: "Petamitta",
      DateOfBirth: "1994-06-25",
      DateOfJoining: "2023-02-01",
    },
    {
      EmployeeId: "1017983",
      Name: "Pagadala Manoj",
      MRNNumber: "AH0000109308",
      Department: "Components - Stores",
      Age: "28",
      Experience: "1.5",
      Location: "Petamitta",
      DateOfBirth: "1996-02-07",
      DateOfJoining: "2024-02-01",
    },
    {
      EmployeeId: "1017973",
      Name: "Amasa Gowri Sankar",
      MRNNumber: "AH0000117636",
      Department: "Production",
      Age: "20",
      Experience: "3.5",
      Location: "Petamitta",
      DateOfBirth: "2004-02-16",
      DateOfJoining: "2024-01-01",
    },
    {
      EmployeeId: "1018097",
      Name: "Gayathri. A",
      MRNNumber: "AH0000109329",
      Department: "Post Injection Moulding",
      Age: "30",
      Experience: "1.5",
      Location: "Petamitta",
      DateOfBirth: "1993-06-30",
      DateOfJoining: "2024-03-01",
    },
    {
      EmployeeId: "1017963",
      Name: "Ballipagu Dinesh Varma",
      MRNNumber: "AH0000109301",
      Department: "Production",
      Age: "20",
      Experience: "2",
      Location: "Petamitta",
      DateOfBirth: "2003-08-15",
      DateOfJoining: "2024-01-01",
    },
    {
      EmployeeId: "1017951",
      Name: "Chandragiri Purnima",
      MRNNumber: "AH0000109285",
      Department: "Quality Assurance",
      Age: "31",
      Experience: "2",
      Location: "Petamitta",
      DateOfBirth: "1992-06-15",
      DateOfJoining: "2023-12-01",
    },
    {
      EmployeeId: "1017952",
      Name: "Sumathi. N",
      MRNNumber: "AH0000109289",
      Department: "Post Injection Moulding",
      Age: "36",
      Experience: "2",
      Location: "Petamitta",
      DateOfBirth: "1988-03-30",
      DateOfJoining: "2023-12-01",
    },
  ],
};

const headers = [
  "Employee Id",
  "Name",
  "MRN Number",
  "Department",
  "Age",
  "Experience",
  "Location",
  "Date Of Birth",
  "Date Of Joining",
];

const DateWiseReport = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(
    moment(new Date()).format("yyyy-MM-DD")
  );
  const authUser = Authentication.authUserVlaue;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [total, setTotal] = useState(0);
  const [totalEmployee, setTotalEmployee] = useState(0);
  const [newReg,setNewReg] = useState(0)
  const [newRegToday,setNewRegToday] = useState(0)

  console.log(selectedDate);
  const fetchData = async (date, page) => {
    try {
      console.log("fetchData", selectedDate);

      const response = await fetch(
        `${config.apiUrl}apimain/employees/date-of-entry/?d=${date}&page=${
          page ? page : currentPage
        }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "JWT " + authUser.accessToeken,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      setData(responseData.data);
      setCurrentPage(responseData.current_page);
      setTotalPages(responseData.total_pages);
      setTotal(responseData.total_entries);
      setTotalEmployee(responseData.total_employees)
      setNewReg(responseData.total_new_reg)
      setNewRegToday(responseData.total_new_reg_today)
    } catch (error) {
      console.log(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData(selectedDate, currentPage);
  }, [selectedDate, currentPage]);

  const handleNextPage = async () => {
    setCurrentPage(page + 1);
  };

  const handleDateChange = async (date) => {
    setSelectedDate(date);
  };

  // if (loading) return <div>Loading...</div>;
  // if (error) return <div>Error: {error}</div>;
  const classes = useStyles();
  const SelectStyles = useSelectStyles();
  return (
    <div style={{ width: "100%", overflowX: "auto", padding: "24px 0px" }}>
      {/* <span className={classes.labelWidth}> {"Select Date"}</span> : */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "10px",
          alignItems: "center",
          gap: '120px'
        }}
      >
        
        <div
          style={{
            border: "1px solid #d7d7d7",
            padding: "12px ",
            borderRadius: "10px",
            fontWeight: 600,
            color: "black",
            fontSize: "16px",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            textTransform: "uppercase",
            
          }}
        >
          Total Employees <br />{" "}
          <div style={{ textAlign: "center" }}>{totalEmployee}</div>
        </div>
        <div
          style={{
            border: "1px solid #d7d7d7",
            padding: "12px ",
            borderRadius: "10px",
            fontWeight: 600,
            color: "black",
            fontSize: "16px",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            textTransform: "uppercase",
          }}
        >
        Total New Registrations <br />{" "}
          <div style={{ textAlign: "center" }}>{newReg}</div>
        </div></div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "10px",
          alignItems: "center",
          gap: '120px'
        }}
      >
         <div
          style={{
            border: "1px solid #d7d7d7",
            padding: "10px ",
            borderRadius: "10px",
            fontWeight: 600,
            color: "black",
            fontSize: "16px",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          }}
        >
          <span className={classes.typeWidth}>
            <TextField
              classes={SelectStyles.root}
              label={"Select Date"}
              type={"date"}
              defaultValue={moment(selectedDate).format("yyyy-MM-DD")}
              name={"Select Date"}
              value={moment(selectedDate).format("yyyy-MM-DD")}
              onChange={async (e) => {
                await handleDateChange(
                  moment(e.target.value).format("yyyy-MM-DD")
                );
                await fetchData(moment(e.target.value).format("yyyy-MM-DD"), 1);
              }}
            />
          </span>
        </div>
      
        <div
          style={{
            border: "1px solid #d7d7d7",
            padding: "12px ",
            borderRadius: "10px",
            fontWeight: 600,
            color: "black",
            fontSize: "16px",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            textTransform: "uppercase",
          }}
        >
        New Registrations<br />{" "}
          <div style={{ textAlign: "center" }}>{newRegToday}</div>
        </div>
        <div
          style={{
            border: "1px solid #d7d7d7",
            padding: "12px ",
            borderRadius: "10px",
            fontWeight: 600,
            color: "black",
            fontSize: "16px",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            textTransform: "uppercase",
          }}
        >
         Total Entries  <br />{" "}
          <div style={{ textAlign: "center" }}>{total}</div>
        </div>
       
      </div>
      <div style={{ padding: "24px 0px" }}>
        {data.length > 0 ? (
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead style={{ backgroundColor: "#c00245", color: "white" }}>
              <tr>
                {headers.map((header) => (
                  <th
                    key={header}
                    style={{ padding: "10px", border: "1px solid white" }}
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td style={{ padding: "10px", border: "1px solid black" }}>
                    {item.EmployeeId}
                  </td>
                  <td style={{ padding: "10px", border: "1px solid black" }}>
                    {item.Name}
                  </td>
                  <td style={{ padding: "10px", border: "1px solid black" }}>
                    {item.MRNNumber}
                  </td>
                  <td style={{ padding: "10px", border: "1px solid black" }}>
                    {item.Department}
                  </td>
                  <td style={{ padding: "10px", border: "1px solid black" }}>
                    {item.Age}
                  </td>
                  <td style={{ padding: "10px", border: "1px solid black" }}>
                    {item.Experience} years
                  </td>
                  <td style={{ padding: "10px", border: "1px solid black" }}>
                    {item.Location}
                  </td>
                  <td style={{ padding: "10px", border: "1px solid black" }}>
                    {item.DateOfBirth}
                  </td>
                  <td style={{ padding: "10px", border: "1px solid black" }}>
                    {item.DateOfJoining}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
            
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "18px",
              color: "black",
              fontWeight: 700,
              textTransform: "uppercase",
            }}
          >
            Entries Not Found
          </div>
        )}
      </div>
      {data.length > 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "10px",
          }}
        >
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={async (e, page) => {
              setCurrentPage(page);
              await fetchData(selectedDate, page);
            }}
            variant="outlined"
            shape="rounded"
            color={"secondary"}
          />
        </div>
      ) : null}
    </div>
  );
};

export default DateWiseReport;
