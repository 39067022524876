import React, { useState, useEffect } from "react"
import { TextField, Select, MenuItem, Checkbox } from "@material-ui/core"
import moment from "moment";


export const TextFieldWrapper = props => {
  const { label, classes, value, name, onHieghtWeightChange, BMI,placeholder } = props;
  //  default values for fields which is not available in some components
  var width = "width" in props ? props.width : "auto"
  var disableUnderline =
    "disableUnderline" in props ? props.disableUnderline : false
  var disable = "disable" in props? props.disable :false
  const [fieldvalue,setValue] =useState(value)


  const onTextChange = (e) => {
    setValue(e.target.value);
    if(e.target.name=="Name"){document.getElementById("disclaimerName").innerHTML=e.target.value;}
    if(e.target.name=="Hight" || e.target.name=="Wight"){
      onHieghtWeightChange({ filedName: name, fieldValue: e.target.value });
    }
  };

  useEffect(()=>{
    if(name=="VacAudioEvaluation"){
      setValue("Reports enclosed")
    }
    
  },[value])

  React.useEffect(() => {
   
    if (name === "BMI") {
      setValue(BMI);
    }
  }, [BMI]);


  return (
    
    <>
      <TextField
        disabled={disable}
        autoComplete='false'
        style={{ width: width }}
        name={name}
        className={classes}
        type='text'
        variant='standard'
        InputProps={{ disableUnderline: disableUnderline }}
        value={fieldvalue}
        placeholder={placeholder}
        onChange={onTextChange}
      />
      {name=="Hight"&& <span>cm</span>}
      {name=="Wight"&& <span>kg</span>}
    </>
  )
}

export const TextAreaFieldWrapper = props => {
  const { label, classes, value, name, onHieghtWeightChange, BMI,placeholder } = props;
  //  default values for fields which is not available in some components
  var width = "width" in props ? props.width : "auto"
  var disableUnderline =
    "disableUnderline" in props ? props.disableUnderline : false
  var disable = "disable" in props? props.disable :false
  const [fieldvalue,setValue] =useState(value)


  const onTextChange = (e) => {
    setValue(e.target.value);
    if(e.target.name=="Name"){document.getElementById("disclaimerName").innerHTML=e.target.value;}
    if(e.target.name=="Hight" || e.target.name=="Wight"){
      onHieghtWeightChange({ filedName: name, fieldValue: e.target.value });
    }
  };

  useEffect(()=>{
    if(name=="VacAudioEvaluation"){
      setValue("Reports enclosed")
    }
    setValue(value)
  },[value])

  React.useEffect(() => {
   
    if (name === "BMI") {
      setValue(BMI);
    }
  }, [BMI]);


  return (
    
    <>
      <TextField
        disabled={disable}
        autoComplete='false'
        style={{ width: width }}
        name={name}
        className={classes}
        type='text'
        variant='standard'
        InputProps={{ disableUnderline: disableUnderline,style: {fontSize: 12} }}
        value={fieldvalue}
        placeholder={placeholder}
        onChange={onTextChange}
      />
      {name=="Hight"&& <span>cm</span>}
      {name=="Wight"&& <span>kg</span>}
    </>
  )
}

export const DateFieldWrapper = props => {
  const { label, classes,value,name } = props
  //  current date is already present
  // var [month, date, year] = new Date().toLocaleDateString().split("/")
  // var dateStr = "" + year + "-" + month + "-" + date
  var dateStr = moment().format("yyyy-MM-DD")
  const [fieldvalue,setValue] =useState(value)
  
 

  useEffect(()=>{
    setValue(dateStr);
    if((name=="DateOfEntry" || name=="VacExpiryDate")&& value!=""){
      setValue(moment(value).format("yyyy-MM-DD"));
    }
  },[value])

  useEffect(()=>{
    if((name=="DateOfEntry")){

      var Doj = document.getElementById("empDateOfJoin").value; 
      var Dob = document.getElementById("empDateOfBirth").value;
      
      if(Dob!==""){
        
        var years = moment().diff(Dob, 'years',true);
        var months = moment().diff(Dob, 'months',true);
        var rounded = Math.floor(months/12);
        var decimal = (months/12) - rounded;
        var AgeOfEmpYear = Math.floor(years);
        var AgeOfEmpMonth = Math.floor(parseFloat(decimal)*12);
        
        document.getElementById("Age").value=AgeOfEmpYear;
      }
      
      if(Doj!==""){
        
        var years = moment().diff(Doj, 'years',true);
        var months = moment().diff(Doj, 'months',true);
        var rounded = Math.floor(months/12);
        var decimal = (months/12) - rounded;
        var AgeOfEmpYear = Math.floor(years);
        var AgeOfEmpMonth = Math.floor(parseFloat(decimal)*12);
        
        document.getElementsByName("Experience")[0].value=AgeOfEmpYear +" years "+AgeOfEmpMonth+" months";
      }
    }
  },[])

  const onDateChagne=(e)=>{
    setValue(e.target.value)
    
    if((name=="DateOfEntry")){

      var Doj = document.getElementById("empDateOfJoin").value; 
      var Dob = document.getElementById("empDateOfBirth").value;
      
      if(Dob!==""){
        
        var years = moment(e.target.value).diff(Dob, 'years',true);
        var months = moment(e.target.value).diff(Dob, 'months',true);
        var rounded = Math.floor(months/12);
        var decimal = (months/12) - rounded;
        var AgeOfEmpYear = Math.floor(years);
        var AgeOfEmpMonth = Math.floor(parseFloat(decimal)*12);
        
        document.getElementById("Age").value=AgeOfEmpYear;
      }
      if(Doj!==""){
        
        var years = moment(e.target.value).diff(Doj, 'years',true);
        var months = moment(e.target.value).diff(Doj, 'months',true);
        var rounded = Math.floor(months/12);
        var decimal = (months/12) - rounded;
        var AgeOfEmpYear = Math.floor(years);
        var AgeOfEmpMonth = Math.floor(parseFloat(decimal)*12);
        
        document.getElementsByName("Experience")[0].value=AgeOfEmpYear +" years "+AgeOfEmpMonth+" months";
      } 
    }
  }

  return (
    <TextField
      // onChange={handleTextChange}
      // value={name in textValues ? textValues[name] : ""}
      defaultValue={dateStr}
      name={name}
      className={classes}
      type='date'
      variant='standard'
      value ={fieldvalue}
      onChange={(e)=>onDateChagne(e)}
    />
  )
}

export const DateTimeFieldWrapper = props => {
  const { label, classes,value,name } = props
  //  current date is already present
  var [month, date, year] = new Date().toLocaleDateString().split("/")
  var dateStr = "" + year + "-" + month + "-" + date
  const [fieldvalue,setValue] =useState(value)
  return (
    <TextField
      // onChange={handleTextChange}
      // value={name in textValues ? textValues[name] : ""}
      defaultValue={dateStr}
      name={name}
      className={classes}
      type='datetime-local'
      variant='standard'
      value ={fieldvalue}
      onChange={(e)=>setValue(e.target.value)}
    />
  )
}

export const NumberFieldWrapper = props => {
  const { label, classes ,value,name} = props
  const [fieldvalue,setValue] =useState(value)

  useEffect(()=>{
    setValue(value)
  },[value])

  return (
    <TextField
      name={name}
      id={name}
      className={classes}
      type='number'
      variant='standard'
      value ={fieldvalue}
      onChange={(e)=>setValue(e.target.value)}
    />
  )
}

export const SelectFieldWrapper = props => {
  const { label, list, classes,value,name, onIsMarriedChange,onGenderChange } = props
  const [fieldvalue,setValue] =useState(value)

  const onSelectChange = (e) => {
    setValue(e.target.value);
    if(e.target.name=="CompName"){document.getElementById("disclaimerCompName").innerHTML=e.target.value;}
    if (label === "Marital Status") {
      onIsMarriedChange(e.target.value);
    }
    
    if(label==='GENDER'){
      onGenderChange(e.target.value)
    }
    
  };

  return (
    <Select
      className={classes}
      id={"demo-select" + label}
      // value={label in dropdownValues ? dropdownValues[label] : ""}
      name={name}
      // value='-1'
      value ={fieldvalue}
      onChange={onSelectChange}
    >
      {list?.map((eachListName, index) => {
        return (
          <MenuItem key={index} value={eachListName}>
            {eachListName}
          </MenuItem>
        )
      })}
    </Select>
  )
}

export const CheckboxWrapper = props => {
  const { label, classes,value,name } = props
  const [fieldvalue,setValue] =useState(value)
  return <Checkbox className={classes} name={name} onChange={(e)=>{setValue(e.target.checked)}} checked={fieldvalue==true?true:false} color='secondary' />
}
