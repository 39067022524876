import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  TextField,
  Select,
  MenuItem,
  makeStyles,
  Button,
} from "@material-ui/core";
import {
  TextFieldWrapper,
  DateFieldWrapper,
  NumberFieldWrapper,
  SelectFieldWrapper,
} from "./Elements";

const useSelectStyles = makeStyles((theme) => ({
  root: {
    width: 130,
  },
}));

const useStyles = makeStyles((theme) => ({
  borderTable: {
    border: "1px solid #d1d1d1",
    margin: "0",
    marginTop:"5px",
    padding: theme.spacing(2),
    backgroundColor: "#fff",
    borderRadius: "5px",
    boxShadow: "0 3px 10px 0 rgb(0 0 0 / 5%)",
  },
  tableStyles: {
    padding: "0 0 0 15px",
  },
  table2Styles: {
    border: "1px solid",
  },
  labelWidth: {
    fontWeight: "bold",
  },
  typeWidth: {
    margin: theme.spacing(1),
  },
  verticalAlign: {
    verticalAlign: "baseline",
  },
  borderTable2: {
    border: "1px solid black",
    margin: "15px 0px",
    padding: 0,
  },
}));

const data = [
  {label: "BP at Rest", name:"BPatRest", type: "text",size: 6,tableType: 2,border: false,width: 300,value:""},
  {label: "Pulse Rate at Rest",name:"PulseRateatRest", type: "text",size: 6,tableType: 2,border: false,width: 300,value:""},
  {label: "Height",name:"Hight", type: "text",size: 3,tableType: 2,border: true,width: 120,pageBreak: true,value:""},
  {label: "Weight",name:"Wight", type: "text",size: 3,tableType: 2,border: true,width: 120,value:""},
  {label: "BMI",name:"BMI", type: "text",size: 2,tableType: 2,border: true,width: 50,value:""},
  {label: "Blood Group & Rh Type",name:"BllodGroupRhType",type: "text",size: 4,tableType: 2,border: true,width: 80,value:""},
  {label: "Marital Status",name:"MaritalStatus",type: "select",list: ["","Single", "Married"],size: 4,tableType: 2,border: true,width: 100,pageBreak: true,value:""},
  {label: "No. of Child",name:"NoofChild", type: "text",size: 4,tableType: 2,border: true,width: 100,value:""},
  {label: "Diet",name:"Dite", type: "select",list: ["","VEG", "NON VEG"],size: 4,tableType: 2,border: true,width: 100,value:""},
  {label: "Smoking",name:"Cigerettes",type: "select",list: ["","YES", "NO"],size: 4,tableType: 2,border: true,width: 100,pageBreak: true,value:""},
  {label: "Tobacco & Snuff",name:"TobaccoSnuff",type: "select",list: ["","YES", "NO"],size: 4,tableType: 2,border: true,width: 100,value:""},
  {label: "Alcohol",name:"Alcohol",type: "select",list: ["","YES", "NO"],size: 4,tableType: 2,width: 100,border: true,value:""},
]
const Item = (props) => {
  const classes = useStyles();
  var tableStyles = classes.tableStyles;
  const { border, size, pageBreak } = props;
  var table2Styles = border ? classes.table2Styles : "";

  return (
    <Grid item sm={size} className={tableStyles + " " + table2Styles}>
      <Element {...props} />
    </Grid>
  );
};
const Element = (props) => {
  const classes = useStyles();
  const SelectStyles = useSelectStyles();
  const {
    label,
    type,
    width,
    name,
    value,
    onIsMarriedChange,
    disableChildNo,
    onHieghtWeightChange,
    BMI
  } = props;
  var verticalAlign = classes.verticalAlign;
  var typeWidth = classes.typeWidth;
  var labelWidth = classes.labelWidth;
  const isDisable = name === "NoofChild" ? disableChildNo : false;
  
  if (["select"].includes(type)) {
    const { list } = props;
    
    return (
      <>
        <span className={labelWidth}> {label}</span> :
        <span className={typeWidth}>
          <SelectFieldWrapper
            classes={SelectStyles.root}
            list={list}
            label={label}
            value={value}
            name={name}
            onIsMarriedChange={onIsMarriedChange}
          />
        </span>
      </>
    );
  }

  if (["date"].includes(type)) {
    return (
      <>
        <span className={labelWidth}> {label}</span> :
        <span className={typeWidth}>
          <DateFieldWrapper
            classes={SelectStyles.root}
            label={label}
            name={name}
            value={value}
          />
        </span>
      </>
    );
  }

  if (["text"].includes(type)) {
    return (
      <div>
        <span className={labelWidth}> {label}</span> :
        <span className={typeWidth}>
          <TextFieldWrapper
            width={width}
            label={label}
            name={name}
            disable={isDisable}
            value={value}
            classes={verticalAlign}
            disableUnderline={true}
            onHieghtWeightChange={onHieghtWeightChange}
            BMI={BMI}
          />
        </span>
      </div>
    );
  }

  if (["number"].includes(type)) {
    return (
      <>
        <span className={labelWidth}> {label}</span> :
        <span className={typeWidth}>
          <NumberFieldWrapper
            type={type}
            label={label}
            name={name}
            value={value}
          />
        </span>
      </>
    );
  }
};
const TableTwo = (props) => {
  const classes = useStyles();
  const SelectStyles = useSelectStyles();

  const [disableChildNo, setDisableChildNo] = useState(true);
  const [enteredHeight, setEnteredHeight] = useState(props.data["Hight"]);
  const [enteredWeight, setEnteredWeight] = useState(props.data["Wight"]);
  const [BMI, setBMI] = useState("");

  const onHieghtWeightChange = (val) => {
    
    if (val.filedName === "Hight") {
      setEnteredHeight(val.fieldValue);
    }
    if (val.filedName === "Wight") {
      setEnteredWeight(val.fieldValue);
    }
  };

  React.useEffect(() => {
    if (enteredHeight && enteredWeight) {
      const bmi = (
        +enteredWeight /
        (((+enteredHeight / 100) * +enteredHeight) / 100)
      ).toFixed(2);
      setBMI(bmi.toString())
      
      document.getElementsByName("BMI").value= bmi.toString()
      // if (bmi <= 18.5) {
      //   setBMI("Underweight");
      // } else if (bmi <= 24.9) {
      //   setBMI("Normal");
      // } else if (bmi <= 29.9) {
      //   setBMI("Overweight");
      // } else {
      //   setBMI("Obesity");
      // }
    }
  }, [enteredHeight, enteredWeight]);

  const onIsMarriedChange = (val) => {

    if (val === "Married") {
      setDisableChildNo(false);
    } else {
      setDisableChildNo(true);
    }
  };

  const [fieldData, setFieldData] = useState(data);
  // const [isChildEnabled, enableChild] = useState(false);

  useEffect(() => {
    if (props.data != undefined && props.data != {}) {
      var dataFromDb = [];
      fieldData.map((field, index) => {
        field["value"] =
          props.data[field["name"]] == undefined
            ? ""
            : props.data[field["name"]];
        dataFromDb.push(field);
      });
      setFieldData(dataFromDb);
      if(props.data["MaritalStatus"]){
        if (props.data["MaritalStatus"] === "YES") {
          setDisableChildNo(false);
        } else {
          setDisableChildNo(true);
        }
      }
    }
  }, [props.data]);

  return (
    <Container className={classes.borderTable}>
      <Grid container>
        {fieldData.map((eachfield, index) => {
          const prop = {
            ...eachfield,
            index,
            onIsMarriedChange,
            disableChildNo,
            onHieghtWeightChange,
            BMI,
          };
          return <Item {...prop} key={index} />;
        })}
      </Grid>
    </Container>
  );
};

export default TableTwo;
