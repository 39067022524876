import React,{Component} from 'react'
import Layout from './components/Layout'
import {Router,Route,Link } from 'react-router-dom'
import {createBrowserHistory} from 'history'
import LoginPage from './components/auth/LoginPage'
import {SecureRoute} from '../src/components/auth/SecureRoute'

export const history = createBrowserHistory();

class App extends Component{

    render() {
        return (
            <Router history={history}>
                <Route exact path="/login" component={LoginPage} />
                <SecureRoute exact path="/" component={Layout} />
           </Router>
        )
    }
}

export default App