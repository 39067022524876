import React from 'react';
import {Route,Redirect} from 'react-router-dom'
import {Authentication} from './Authenticatoin'

export const SecureRoute=({component:Component,...rest})=>(
    <Route {...rest} render={props=>{
        const authUser =Authentication.authUserVlaue
        
        if(!authUser){
            return <Redirect to={{pathname:'/login',state:{from:props.location}}}/>
        }

        return <Component {...props}/>
        
    }}/>
)