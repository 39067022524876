import React, { useEffect, useState } from "react"
import {
  Container,
  Grid,
  TextField,
  Select,
  MenuItem,
  makeStyles,
  Button,
  Typography,
} from "@material-ui/core"
import { SelectFieldWrapper } from "./Elements"

const useSelectStyles = makeStyles(theme => ({
  root: {
    width: 130,
  },
}))

const useStyles = makeStyles(theme => ({
  borderTable: {
    border: "1px solid #d1d1d1",
    margin: "0 0px",
    padding: theme.spacing(2),
    backgroundColor:"#fff",
    borderRadius:"5px",
    boxShadow:"0 3px 10px 0 rgb(0 0 0 / 5%)"
  },
  tableStyles: {
    padding: "0 0 0 15px"
  },
  table2Styles: {
    border: "1px solid",
  },
  labelWidth: {
    display: "inline-block",
    width: 300,
    fontWeight: "bold",
  },
  typeWidth: {
    margin: theme.spacing(5),
  },
  verticalAlign: {
    verticalAlign: "baseline",
  },
  borderTable1: {
    border: "1px solid #d1d1d1",
    margin: "0px 0px",
    padding: theme.spacing(2),
    backgroundColor:"#fff",
    borderRadius:"5px",
    boxShadow:"0 3px 10px 0 rgb(0 0 0 / 5%)"
  },
  borderTable2: {
    border: "1px solid #d1d1d1",
    margin: "0",
    padding: theme.spacing(2),
    backgroundColor:"#fff",
    borderRadius:"5px",
    boxShadow:"0 3px 10px 0 rgb(0 0 0 / 5%)"
  },
  table4Styles: {
    borderTop: "none",
  },
}))

const data = [
  {
    label: "Asthma",
    name:"Asthma",
    type: "checkbox",
    self: ["YES", "NO"],
    father: ["YES", "NO"],
    mother: ["YES", "NO"],
    sister: ["YES", "NO"],
    brother: ["YES", "NO"],
    tableType: 4,
    value:{AsthmaFather:"",AsthmaMother:"",AsthmaSister:"",AsthmaBrother:"",AsthmaSelf:""}
  },

  {
    label: "Hyper Tension",
    name: "HyperTension",
    type: "checkbox",
    self: ["YES", "NO"],
    father: ["YES", "NO"],
    mother: ["YES", "NO"],
    sister: ["YES", "NO"],
    brother: ["YES", "NO"],
    tableType: 4,
    value:{HyperTensionFather:"",HyperTensionMother:"",HyperTensionSister:"",HyperTensionBrother:"",HyperTensionSelf:""}
  },
  {
    label: "Diabetes",
    name: "Diabetes",
    type: "checkbox",
    self: ["YES", "NO"],
    father: ["YES", "NO"],
    mother: ["YES", "NO"],
    sister: ["YES", "NO"],
    brother: ["YES", "NO"],
    tableType: 4,
    value:{DiabetesFather:"",DiabetesMother:"",DiabetesSister:"",DiabetesBrother:"",DiabetesSelf:""}
  },
  {
    label: "HeartDisease",
    name: "HeartDisease",
    type: "checkbox",
    self: ["YES", "NO"],
    father: ["YES", "NO"],
    mother: ["YES", "NO"],
    sister: ["YES", "NO"],
    brother: ["YES", "NO"],
    tableType: 4,
    value:{HeartDiseaseFather:"",HeartDiseaseMother:"",HeartDiseaseSister:"",HeartDiseaseBrother:"",HeartDiseaseSelf:""}
  },
  {
    label: "Stroke",
    name: "Stroke",
    type: "checkbox",
    self: ["YES", "NO"],
    father: ["YES", "NO"],
    mother: ["YES", "NO"],
    sister: ["YES", "NO"],
    brother: ["YES", "NO"],
    tableType: 4,
    value:{StrokeFather:"",StrokeMother:"",StrokeSister:"",StrokeBrother:"",StrokeSelf:""}
  },
]
const TableFour = props => {
  const classes = useStyles()
  const SelectStyles = useSelectStyles()
  const [fieldData, setFieldData]=useState(data)

  const DropdownValuesComponent = props => {
    var {
      label: labelReadable,
      classes,
      self,
      father,
      mother,
      brother,
      sister,
      name,
      value
    } = props
    var label = labelReadable.replaceAll(" ", "_")
    var table4Styles = classes.table4Styles
    var tableStyles = classes.tableStyles

    return (
      <Grid item sm={12} className={[tableStyles, table4Styles].join(" ")}>
        <Grid container alignContent='center'>
          <Grid item sm={2} className={classes.labelWidth}>
            <span> {labelReadable}</span>
          </Grid>
          <Grid item sm={2}>
            <SelectFieldWrapper
              classes={SelectStyles.root}
              list={self}
              label={label + "_Self"}
              value={value[name+"Self"]}
              name={name+"Self"}
            />
          </Grid>
          <Grid item sm={2}>
            <SelectFieldWrapper
              classes={SelectStyles.root}
              list={father}
              label={label + "_father"}
              value={value[name+"Father"]}
              name={name+"Father"}
            />
          </Grid>
          <Grid item sm={2}>
            <SelectFieldWrapper
              classes={SelectStyles.root}
              list={mother}
              label={label + "_mother"}
              value={value[name+"Mother"]}
              name={name+"Mother"}
            />
          </Grid>
          <Grid item sm={2}>
            <SelectFieldWrapper
              classes={SelectStyles.root}
              list={sister}
              label={label + "_sister"}
              value={value[name+"Sister"]}
              name={name+"Sister"}
            />
          </Grid>
          <Grid item sm={2}>
            <SelectFieldWrapper
              classes={SelectStyles.root}
              list={brother}
              label={label + "_brother"}
              value={value[name+"Brother"]}
              name={name+"Brother"}
            />
          </Grid>
        </Grid>
      </Grid>
    )
  }

  useEffect(() => {
    if(props.data!=undefined && props.data!={}){
      var dataFromDb = []
      fieldData.map((field,index)=>{
        var values = field["value"]
        Object.keys(values).map((header)=>{
          values[header] = props.data[header]==undefined?"":props.data[header]
        })
        field["value"] = values
        dataFromDb.push(field)
      })
      setFieldData(dataFromDb)
    }
  
  }, [props.data])

  return (
    <div >
      <Typography align='center' variant='h6'>
        FAMILY HISTORY
      </Typography>
      <Container className={classes.borderTable2}>
        <Grid container justifyContent='center'>
          <Grid item sm={2} />
          {["SELF","FATHER", "MOTHER", "SISTER", "BROTHER"].map((eachName, index) => {
            return (
              <Grid item sm={2} key={index}>
                <Typography align='center' style={{ fontWeight: "bold" }}>
                  {eachName}
                </Typography>
              </Grid>
            )
          })}
        </Grid>
        <Grid container>
          {fieldData.map((eachfield, index) => {
            const prop = { classes: classes, ...eachfield }
            return <DropdownValuesComponent {...prop} key={index} />
          })}
        </Grid>
      </Container>
    </div>
  )
}

export default TableFour
