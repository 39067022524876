import React,{Component} from 'react'
import { Container, Grid, makeStyles } from "@material-ui/core"
import moment from 'moment';


class Reports extends Component{

    constructor(props) {
        super(props)
        this.state = {
            hematologyHeaders:{Hb:'HAEMOGLOBIN',Mch:'MEAN CORPUSCULAR HEMOGLOBIN (MCH)',Mchc:'MEAN CORPUSCULAR HEMOGLOBIN CONCENTRATION (MCHC)',
                Mcv:'MEAN CORPUSCULAR VOLUME (MCV)',Plt:'PLATELET COUNT',Rbc:'RBC COUNT',Wbc:'WBC COUNT',Hct:'HAEMATOCRIT',Neut:'NEUTROPHILS',
                Lymph:'LYMPHOCYTES',Mono:'MONOCYTES',Baso:'BASOPHILS',Eo:'EOSINOPHILS',Bg:'BLOOD GROUP & RH FACTOR '},
            bioChemHeaders:{Fbs:'FASTING BLOOD SUGAR',Ppbs:'POST PRANDIAL SUGAR',Rbs:'Random Blood Sugar',Tchdlr:'TOTAL CHOLESTEROL/HDL-C RATIO',Vld:'VLDL',Hdl:'HDL',Ldl:'LDL',
                Tcho:'TOTAL CHOLESTEROL',Tgl:'TRIGLYCERIDES',Bun:'BLOOD URIEA',Scre:'SERUM CREATININE',Ua:'URIC ACID',Na:'SODIUM',Ka:'POTASSIUM',
                Cl:'CLORIDE',Tbill:'TOTAL BILBURINE',Dbi:'DIRECT BILBURINE',Idbi:'INDIRECT BILBURIN',Alt:'ALANINE AMINOTRANSFERASE',
                Ast:'ASPRATE AMINOTRANSFERASE',Alp:'ALKALINE PHOSPHATASE',Tp:'TOTAL PROTINE',Alb:'SERIUM ALBUMIN',Glob:'GLOBLINE',
                Agr:'ALBUMIN / GLOBULIN RATIO',Ggt:'GAMA-GLUTAMUL TRANSFERASE',Tsh:'THYROID STIMULATING HORMONE',Tpsa:'TOTAL PROSTATE SPECIFIC ANTIGEN'},
            clinicPathHeaders:{Urineblood:'URINEBLOOD',Glu:'URINE GLUCOSE',Bil:'URINE BILIRUBIN',Ket:'KETONE',Sg:'SPECIFIC GRAVITY',Ph:'URINE PH',Pro:' URINE PROTEIN',
                Uro:'URINE UROBILINOGEN',Nit:' URINE NITRITE',Leu:'URINE LEUCOCYTES'},
            microbHeaders:{Ovaparasite:'STOOL FOR OVA',Srme:'Stool Routine'},
            reportsData:{Hematology:[],Biochemistry:[],ClinicalPathalogy:[],MicroBiology:[]}
        }
      }

    componentDidMount(){
        this.setState({reportsData:this.props.data}) 
    }

    componentWillReceiveProps(){
        this.setState({reportsData:this.props.data}) 
    }

    render(){
        
        return(
            <React.Fragment>
                {this.state.reportsData.Hematology.length>0 &&
                    <Container style={{border: "1px solid #d1d1d1",margin: "10px 0px",padding: "1px",backgroundColor:"#fff",
                    borderRadius:"5px",boxShadow:"0 3px 10px 0 rgb(0 0 0 / 5%)",pageBreakBefore:"always"}}>
                        <Grid container>
                            <div className="row" style={{width:"100%",padding:"40px 25px",margin:"0"}}>
                                <div className="col-lg-12" style={{textAlign:'center'}}>
                                    <h3>Department of LABORATORY MEDICIN</h3>
                                    <p><b>Lab Report : LAB HAEMATOLOGY</b></p>
                                </div>
                                <h1 style={{width: "100%",backgroundColor: "darkgray",height:" 2px"}}/>
                                <div className="col-lg-12" style={{textAlign:'center'}}>
                                    <table style={{width:"100%"}}>
                                        <tbody style={{color:"black",textAlign:"left"}}>
                                            <tr>
                                                <td style={{width:"20%",fontWeight:600}}>MRN:</td>
                                                <td style={{width:"30%"}}>{this.props.empData["MRNNumber"]}</td>
                                                <td style={{width:"20%",fontWeight:600}}>Department:</td>
                                                <td style={{width:"30%"}}>DERMATOLOGY</td>
                                            </tr>
                                            <tr>
                                                <td style={{width:"20%",fontWeight:600}}>Name:</td>
                                                <td style={{width:"30%"}}>{this.props.empData["Name"]}</td>
                                                <td style={{width:"20%",fontWeight:600}}>Date:</td>
                                                <td style={{width:"30%"}}>{moment().format('MM/DD/YYYY')}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <h1 style={{width: "100%",backgroundColor: "darkgray",height:" 2px"}}/>
                                <div className="col-lg-12" style={{textAlign:'center'}}>
                                    <div className="table-responsive">
                                        <table className="table table-hover">
                                            <thead>
                                                <tr style={{color:"black"}}>
                                                    <th style={{borderTop:"none"}} className="text-center">Test Name</th>
                                                    <th style={{borderTop:"none"}} className="text-center">Result</th>
                                                    <th style={{borderTop:"none"}} className="text-center">Biological Reference Interval</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Object.keys(this.state.hematologyHeaders).map((header,key)=>{
                                                    return(
                                                        <React.Fragment>
                                                            {this.props.data.Hematology[0][header]!="" &&
                                                                <tr>
                                                                    <td style={{padding:"5px"}}><h6 className="text-left"> {this.state.hematologyHeaders[header]}</h6></td>
                                                                    <td style={{padding:"5px"}}><h6 className="text-center">{this.props.data.Hematology[0][header]} </h6></td>
                                                                    <td style={{padding:"5px"}}>
                                                                        <h6 className="text-center">
                                                                            {this.props.data.refData.filter(x=>x.Field==header).length>0 &&
                                                                                <>
                                                                                    {this.props.empData["Sex"]=="MALE" && 
                                                                                        <>
                                                                                            <span>{this.props.data.refData.filter(x=>x.Field==header)[0]["MinMale"]}</span> - 
                                                                                            <span> {this.props.data.refData.filter(x=>x.Field==header)[0]["MaxMale"]}</span>   
                                                                                        </>
                                                                                    }
                                                                                    {this.props.empData["Sex"]=="FEMALE" && 
                                                                                        <>
                                                                                            <span>{this.props.data.refData.filter(x=>x.Field==header)[0]["MinFemale"]}</span> - 
                                                                                            <span> {this.props.data.refData.filter(x=>x.Field==header)[0]["MaxFemale"]}</span>   
                                                                                        </>
                                                                                    }
                                                                                </>
                                                                            }
                                                                        </h6>
                                                                    </td>
                                                                </tr>
                                                            }
                                                        </React.Fragment>
                                                        
                                                    )
                                                })}
                                                 
                                            </tbody>
                                            
                                        </table>
                                    </div>
                                </div>
                                <div className="col-lg-12" style={{textAlign:'left'}}>
                                    <p style={{fontSize:"12px"}}><b>Note:Results relate to the sample only.Kindly correlate clinically.</b></p>
                                </div>
                                <div className="col-lg-12" style={{textAlign:'right',color:"black",paddingRight:"50px"}}>
                                    <p><b>Signature Of The Doctor</b></p>
                                </div>
                                <div className="col-lg-12" style={{textAlign:'center',color:"black",paddingRight:"50px"}}>
                                    <p><b>---- End of Report ---- </b></p>
                                </div>
                            </div>
                        </Grid>
                    </Container>
                }

                {this.state.reportsData.Biochemistry.length>0 &&
                    <Container style={{border: "1px solid #d1d1d1",margin: "10px 0px",padding: "1px",backgroundColor:"#fff",
                    borderRadius:"5px",boxShadow:"0 3px 10px 0 rgb(0 0 0 / 5%)",pageBreakBefore:"always"}}>
                        <Grid container>
                            <div className="row" style={{width:"100%",padding:"40px 25px",margin:"0"}}>
                                <div className="col-lg-12" style={{textAlign:'center'}}>
                                    <h3>Department of LABORATORY MEDICIN</h3>
                                    <p><b>Lab Report : LAB BIOCHEMISTRY</b></p>
                                </div>
                                <h1 style={{width: "100%",backgroundColor: "darkgray",height:" 2px"}}/>
                                <div className="col-lg-12" style={{textAlign:'center'}}>
                                    <table style={{width:"100%"}}>
                                        <tbody style={{color:"black",textAlign:"left"}}>
                                            <tr>
                                                <td style={{width:"20%",fontWeight:600}}>MRN:</td>
                                                <td style={{width:"30%"}}>{this.props.empData["MRNNumber"]}</td>
                                                <td style={{width:"20%",fontWeight:600}}>Department:</td>
                                                <td style={{width:"30%"}}>DERMATOLOGY</td>
                                            </tr>
                                            <tr>
                                                <td style={{width:"20%",fontWeight:600}}>Name:</td>
                                                <td style={{width:"30%"}}>{this.props.empData["Name"]}</td>
                                                <td style={{width:"20%",fontWeight:600}}>Date:</td>
                                                <td style={{width:"30%"}}>{moment().format('MM/DD/YYYY')}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <h1 style={{width: "100%",backgroundColor: "darkgray",height:" 2px"}}/>
                                <div className="col-lg-12" style={{textAlign:'center'}}>
                                    <div className="table-responsive">
                                        <table className="table table-hover">
                                            <thead>
                                                <tr style={{color:"black"}}>
                                                    <th style={{borderTop:"none"}} className="text-center">Test Name</th>
                                                    <th style={{borderTop:"none"}} className="text-center">Result</th>
                                                    <th style={{borderTop:"none"}} className="text-center">Biological Reference Interval</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Object.keys(this.state.bioChemHeaders).map((header,key)=>{
                                                    
                                                    return(
                                                        <React.Fragment>
                                                            {this.props.data.Biochemistry[0][header]!="" && this.props.data.Biochemistry[0][header]!=undefined &&
                                                                <>
                                                                {this.props.data.Biochemistry[0][header].trim()!="" &&
                                                                    <tr>
                                                                        <td style={{padding:"5px"}}><h6 className="text-left"> {this.state.bioChemHeaders[header]}</h6></td>
                                                                        <td style={{padding:"5px"}}><h6 className="text-center">{this.props.data.Biochemistry[0][header]} </h6></td>
                                                                        <td style={{padding:"5px"}}>
                                                                            <h6 className="text-center">
                                                                                {this.props.data.refData.filter(x=>x.Field==header).length>0 &&
                                                                                    <>
                                                                                        {this.props.empData["Sex"]=="MALE" && 
                                                                                            <>
                                                                                                <span>{this.props.data.refData.filter(x=>x.Field==header)[0]["MinMale"]}</span> - 
                                                                                                <span> {this.props.data.refData.filter(x=>x.Field==header)[0]["MaxMale"]}</span>   
                                                                                            </>
                                                                                        }
                                                                                        {this.props.empData["Sex"]=="FEMALE" && 
                                                                                            <>
                                                                                                <span>{this.props.data.refData.filter(x=>x.Field==header)[0]["MinFemale"]}</span> - 
                                                                                                <span> {this.props.data.refData.filter(x=>x.Field==header)[0]["MaxFemale"]}</span>   
                                                                                            </>
                                                                                        }
                                                                                    </>
                                                                                }
                                                                            </h6>
                                                                        </td>
                                                                    </tr>
                                                                }
                                                                </>
                                                                
                                                            }
                                                        </React.Fragment>
                                                        
                                                    )
                                                })}
                                                 
                                            </tbody>
                                            
                                        </table>
                                    </div>
                                </div>
                                <div className="col-lg-12" style={{textAlign:'left'}}>
                                    <p style={{fontSize:"12px"}}><b>Note:Results relate to the sample only.Kindly correlate clinically.</b></p>
                                </div>
                                <div className="col-lg-12" style={{textAlign:'right',color:"black",paddingRight:"50px"}}>
                                    <p><b>Signature Of The Doctor</b></p>
                                </div>
                                <div className="col-lg-12" style={{textAlign:'center',color:"black",paddingRight:"50px"}}>
                                    <p><b>---- End of Report ---- </b></p>
                                </div>
                            </div>
                        </Grid>
                    </Container>
                }

                {this.state.reportsData.ClinicalPathalogy.length>0 &&
                    <Container style={{border: "1px solid #d1d1d1",margin: "10px 0px",padding: "1px",backgroundColor:"#fff",
                    borderRadius:"5px",boxShadow:"0 3px 10px 0 rgb(0 0 0 / 5%)",pageBreakBefore:"always"}}>
                        <Grid container>
                            <div className="row" style={{width:"100%",padding:"40px 25px",margin:"0"}}>
                                <div className="col-lg-12" style={{textAlign:'center'}}>
                                    <h3>Department of LABORATORY MEDICIN</h3>
                                    <p><b>Lab Report : LAB CLINICAL PATHALOGY</b></p>
                                </div>
                                <h1 style={{width: "100%",backgroundColor: "darkgray",height:" 2px"}}/>
                                <div className="col-lg-12" style={{textAlign:'center'}}>
                                    <table style={{width:"100%"}}>
                                        <tbody style={{color:"black",textAlign:"left"}}>
                                            <tr>
                                                <td style={{width:"20%",fontWeight:600}}>MRN:</td>
                                                <td style={{width:"30%"}}>{this.props.empData["MRNNumber"]}</td>
                                                <td style={{width:"20%",fontWeight:600}}>Department:</td>
                                                <td style={{width:"30%"}}>DERMATOLOGY</td>
                                            </tr>
                                            <tr>
                                                <td style={{width:"20%",fontWeight:600}}>Name:</td>
                                                <td style={{width:"30%"}}>{this.props.empData["Name"]}</td>
                                                <td style={{width:"20%",fontWeight:600}}>Date:</td>
                                                <td style={{width:"30%"}}>{moment().format('MM/DD/YYYY')}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <h1 style={{width: "100%",backgroundColor: "darkgray",height:" 2px"}}/>
                                <div className="col-lg-12" style={{textAlign:'center'}}>
                                    <div className="table-responsive">
                                        <table className="table table-hover">
                                            <thead>
                                                <tr style={{color:"black"}}>
                                                    <th style={{borderTop:"none"}} className="text-center">Test Name</th>
                                                    <th style={{borderTop:"none"}} className="text-center">Result</th>
                                                    <th style={{borderTop:"none"}} className="text-center">Biological Reference Interval</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Object.keys(this.state.clinicPathHeaders).map((header,key)=>{
                                                    return(
                                                        <React.Fragment>
                                                            {this.props.data.ClinicalPathalogy[0][header]!="" &&
                                                                <tr>
                                                                    <td style={{padding:"5px"}}><h6 className="text-left"> {this.state.clinicPathHeaders[header]}</h6></td>
                                                                    <td style={{padding:"5px"}}><h6 className="text-center">{this.props.data.ClinicalPathalogy[0][header]} </h6></td>
                                                                    <td style={{padding:"5px"}}>
                                                                        <h6 className="text-center">
                                                                            {this.props.data.refData.filter(x=>x.Field==header).length>0 &&
                                                                                <>
                                                                                    {this.props.empData["Sex"]=="MALE" && 
                                                                                        <>
                                                                                            <span>{this.props.data.refData.filter(x=>x.Field==header)[0]["MinMale"]}</span> - 
                                                                                            <span> {this.props.data.refData.filter(x=>x.Field==header)[0]["MaxMale"]}</span>   
                                                                                        </>
                                                                                    }
                                                                                    {this.props.empData["Sex"]=="FEMALE" && 
                                                                                        <>
                                                                                            <span>{this.props.data.refData.filter(x=>x.Field==header)[0]["MinFemale"]}</span> - 
                                                                                            <span> {this.props.data.refData.filter(x=>x.Field==header)[0]["MaxFemale"]}</span>   
                                                                                        </>
                                                                                    }
                                                                                </>
                                                                            }
                                                                        </h6>
                                                                    </td>
                                                                </tr>
                                                            }
                                                        </React.Fragment>
                                                        
                                                    )
                                                })}
                                                 
                                            </tbody>
                                            
                                        </table>
                                    </div>
                                </div>
                                <div className="col-lg-12" style={{textAlign:'left'}}>
                                    <p style={{fontSize:"12px"}}><b>Note:Results relate to the sample only.Kindly correlate clinically.</b></p>
                                </div>
                                <div className="col-lg-12" style={{textAlign:'right',color:"black",paddingRight:"50px"}}>
                                    <p><b>Signature Of The Doctor</b></p>
                                </div>
                                <div className="col-lg-12" style={{textAlign:'center',color:"black",paddingRight:"50px"}}>
                                    <p><b>---- End of Report ---- </b></p>
                                </div>
                            </div>
                        </Grid>
                    </Container>
                }

                {this.state.reportsData.MicroBiology.length>0 &&
                    <Container style={{border: "1px solid #d1d1d1",margin: "10px 0px",padding: "1px",backgroundColor:"#fff",
                    borderRadius:"5px",boxShadow:"0 3px 10px 0 rgb(0 0 0 / 5%)",pageBreakBefore:"always"}}>
                        <Grid container>
                            <div className="row" style={{width:"100%",padding:"40px 25px",margin:"0"}}>
                                <div className="col-lg-12" style={{textAlign:'center'}}>
                                    <h3>Department of LABORATORY MEDICIN</h3>
                                    <p><b>Lab Report : LAB MICROBIOLOGY</b></p>
                                </div>
                                <h1 style={{width: "100%",backgroundColor: "darkgray",height:" 2px"}}/>
                                <div className="col-lg-12" style={{textAlign:'center'}}>
                                    <table style={{width:"100%"}}>
                                        <tbody style={{color:"black",textAlign:"left"}}>
                                            <tr>
                                                <td style={{width:"20%",fontWeight:600}}>MRN:</td>
                                                <td style={{width:"30%"}}>{this.props.empData["MRNNumber"]}</td>
                                                <td style={{width:"20%",fontWeight:600}}>Department:</td>
                                                <td style={{width:"30%"}}>DERMATOLOGY</td>
                                            </tr>
                                            <tr>
                                                <td style={{width:"20%",fontWeight:600}}>Name:</td>
                                                <td style={{width:"30%"}}>{this.props.empData["Name"]}</td>
                                                <td style={{width:"20%",fontWeight:600}}>Date:</td>
                                                <td style={{width:"30%"}}>{moment().format('MM/DD/YYYY')}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <h1 style={{width: "100%",backgroundColor: "darkgray",height:" 2px"}}/>
                                <div className="col-lg-12" style={{textAlign:'center'}}>
                                    <div className="table-responsive">
                                        <table className="table table-hover">
                                            <thead>
                                                <tr style={{color:"black"}}>
                                                    <th style={{borderTop:"none"}} className="text-center">Test Name</th>
                                                    <th style={{borderTop:"none"}} className="text-center">Result</th>
                                                    <th style={{borderTop:"none"}} className="text-center">Biological Reference Interval</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Object.keys(this.state.microbHeaders).map((header,key)=>{
                                                    return(
                                                        <React.Fragment>
                                                            {this.props.data.MicroBiology[0][header]!="" &&
                                                                <tr>
                                                                    <td style={{padding:"5px"}}><h6 className="text-left"> {this.state.microbHeaders[header]}</h6></td>
                                                                    <td style={{padding:"5px"}}><h6 className="text-center">{this.props.data.MicroBiology[0][header]} </h6></td>
                                                                    <td style={{padding:"5px"}}>
                                                                        <h6 className="text-center">
                                                                            {this.props.data.refData.filter(x=>x.Field==header).length>0 &&
                                                                                <>
                                                                                    {this.props.empData["Sex"]=="MALE" && 
                                                                                        <>
                                                                                            <span>{this.props.data.refData.filter(x=>x.Field==header)[0]["MinMale"]}</span> - 
                                                                                            <span> {this.props.data.refData.filter(x=>x.Field==header)[0]["MaxMale"]}</span>   
                                                                                        </>
                                                                                    }
                                                                                    {this.props.empData["Sex"]=="FEMALE" && 
                                                                                        <>
                                                                                            <span>{this.props.data.refData.filter(x=>x.Field==header)[0]["MinFemale"]}</span> - 
                                                                                            <span> {this.props.data.refData.filter(x=>x.Field==header)[0]["MaxFemale"]}</span>   
                                                                                        </>
                                                                                    }
                                                                                </>
                                                                            }
                                                                        </h6>
                                                                    </td>
                                                                </tr>
                                                            }
                                                        </React.Fragment>
                                                        
                                                    )
                                                })}
                                                 
                                            </tbody>
                                            
                                        </table>
                                    </div>
                                </div>
                                <div className="col-lg-12" style={{textAlign:'left'}}>
                                    <p style={{fontSize:"12px"}}><b>Note:Results relate to the sample only.Kindly correlate clinically.</b></p>
                                </div>
                                <div className="col-lg-12" style={{textAlign:'right',color:"black",paddingRight:"50px"}}>
                                    <p><b>Signature Of The Doctor</b></p>
                                </div>
                                <div className="col-lg-12" style={{textAlign:'center',color:"black",paddingRight:"50px"}}>
                                    <p><b>---- End of Report ---- </b></p>
                                </div>
                            </div>
                        </Grid>
                    </Container>
                }
                
            </React.Fragment>

        )
    }
}
export default Reports