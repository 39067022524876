import React,{Component} from 'react'
import {ValidatorForm,TextValidator} from 'react-material-ui-form-validator';
import { Button } from '@material-ui/core';
//import logoFull from '../../../static/images/fullicon.png'
import Swal from 'sweetalert2'
import {Authentication} from './Authenticatoin'
import BeatLoader from "react-spinners/BeatLoader";
import Modal from '@material-ui/core/Modal';

class LoginPage extends Component{

    constructor(props) {
        super(props);
        this.state = {
          userName:"",
          password:"",
          loaded: false,
          submitted:false,
          loaderModel:false
        };
    };

    handleSubmit=()=>{
        this.setState({loaderModel:true});
        Authentication.login(this.state.userName,this.state.password)
            .then(
                user=>{
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                    this.setState({loaderModel:false});
                    window.location.reload();
                },
                error=>{
                    if(error=="Bad Request"){
                        this.setState({loaderModel:false});
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: 'Invalid credentials!'
                        });
                    }else{
                        this.setState({loaderModel:false});
                        var errors =""
                        error.map(x=>errors+=x)
                        //swal('Error!',errors,'error')
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: errors
                        });
                    }   
                }
            )
    }

    handleChange =(event)=>{
        this.setState({[event.target.name] : event.target.value});
    }

    handleClose = () => {
        this.setState({loaderModel:false});
    };

    render() {
        const inputStyle = {style:{fontSize:14,fontfamily:"Open Sans",marginTop:"15px"}}
        const inputLabelStyle = {style:{fontSize:14,fontfamily:"Open Sans"}}
        const {submitted,userName,password} = this.state;
        const color = "#f50057";
        const loading = true;
        
        return (

            <div className="auth-wrapper">
                {/* <Dialog aria-labelledby="simple-dialog-title" open={true} style={{backgroundColor:"transparent"}}>
                    <BeatLoader color={color} loading={loading} size={20} />
                </Dialog> */}
                <Modal
                    open={this.state.loaderModel}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    style={{display:'flex',alignItems:'center',justifyContent:'center'}} >

                    <BeatLoader color={color} loading={loading} size={17} />
                </Modal>

                <div className="auth-content">
                    {/* <div className="auth-bg">
                        <span className="r"></span>
                        <span className="r s"></span>
                        <span className="r s"></span>
                        <span className="r"></span>
                    </div> */}
                    <div className="card">
                        <div className="card-body text-center">
                            <div className="mb-4">
                                <img style={{width:"100px"}} src="../static/images/fullicon.png"/>
                                {/* <i className="feather icon-unlock auth-icon"></i> */}
                            </div>
                            <h4 className="mb-4">Login</h4>
                            <ValidatorForm style={{width:"100%",padding:"0 0 15px 0"}} ref="form" onSubmit={this.handleSubmit}>
                                <TextValidator InputLabelProps={inputStyle} InputProps={inputStyle} variant="outlined" size="small" label='User Name'
                                        onChange={this.handleChange} name="userName" value={userName}
                                        validators={['required']} errorMessages={['user name is required']} autoComplete='off' fullWidth>
                                </TextValidator>
                                <TextValidator type="password" InputLabelProps={inputStyle} InputProps={inputStyle} variant="outlined" size="small" label='Password'
                                        onChange={this.handleChange} name="password" value={password}
                                        validators={['required']} errorMessages={['password is required']} autoComplete='off' fullWidth>
                                </TextValidator>
                                <Button color="secondary" variant="contained" type="submit" disabled={submitted} style={{marginTop:"20px"}}>
                                        { (submitted && 'Your form is submitted!') || (!submitted && 'Submit') }
                                </Button>
                            </ValidatorForm>
                            
                            {/* <p className="mb-2 text-muted">Forgot password? <a href="#" onClick={(e)=>{e.preventDefault();this.props.history.push('/reset')}}>Reset</a></p> */}
                            {/* <p className="mb-0 text-muted">Don’t have an account? <a href="#" onClick={(e)=>{e.preventDefault();this.props.history.push('/signup')}}>Signup</a></p> */}
                        </div>
                    </div>
                </div>
            
                
            </div>
        )
    }
} 

export default LoginPage