import React from "react"
import { Container, Button, Popper, makeStyles } from "@material-ui/core"
import { Add } from "@material-ui/icons"

const useStyles = makeStyles(theme => ({
  button: {
    height: theme.spacing(6),
    fontSize: 18,
  },
}))

const Home = props => {
  const { goToDashboard } = props
  const classes = useStyles()
  return (
    <Container style={{paddingTop:"35px"}}>
      <Button
        className={classes.button}
        onClick={() => {
          goToDashboard()
        }}
        variant='contained'
        color='secondary'
        startIcon={<Add />}
        size='large'
      >
        CREATE NEW REGISTRATION
      </Button>
    </Container>
  )
}

export default Home
