import { BehaviorSubject } from 'rxjs'
import config from 'config'
import { history } from './history'

const authUserSubject= new BehaviorSubject(JSON.parse(localStorage.getItem('AuthUser')));

export const Authentication={
    login,
    logout,
    authUser:authUserSubject.asObservable(),
    get authUserVlaue(){return authUserSubject.value}
}

function login (EmployeeId,Password) {
    const fetchOptions = {
        method:'POST',
        headers:{'Content-Type': 'application/json'},
        body:JSON.stringify({EmployeeId,Password})
    }
    
    // var user={
    //     name:"TestUser",
    //     userId:"TestUser",
    //     accessToeken : "TestToken",
    //     RefreshToken:"TestRefreshToekn"
    // }

    // localStorage.setItem('AuthUser',JSON.stringify(user));
    // authUserSubject.next(user);

    return fetch(`${config.apiUrl}`+"apimain/login",fetchOptions)
        .then(handleResponse)
        .then(user=>{
            
            var userLocal={
                name:user.data.UserName,
                userId:user.data.EmployeeId,
                role:user.data.Role,
                accessToeken : user.data.AccessTkn,
                refreshToken:user.data.RefreshTkn,
            }
            
            localStorage.setItem('AuthUser',JSON.stringify(userLocal))
            authUserSubject.next(userLocal);
            
            return userLocal;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        
        if (!response.ok) {
            if ([401, 403].indexOf(response.status) !== -1) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                logout();
            }
            var error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        if("Fail" in data){
            return Promise.reject(data["Errors"]);    
        }

        return data;
    });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('AppUser');
    authUserSubject.next(null);
    history.push('/login')
    window.location.reload()
}
