import React, { useEffect, useState } from "react"
import {
  Container,
  Grid,
  TextField,
  Select,
  MenuItem,
  makeStyles,
  Button,
  Typography,
} from "@material-ui/core"

import {
  TextFieldWrapper,
  DateFieldWrapper,
  NumberFieldWrapper,
  SelectFieldWrapper,
  TextAreaFieldWrapper,
} from "./Elements"

const useSelectStyles = makeStyles(theme => ({
  root: {
    width: 110,
  },
}))

const useStyles = makeStyles(theme => ({
  borderTable: {
    border: "1px solid #d1d1d1",
    margin: "15px 0px",
    padding: theme.spacing(2),
    backgroundColor:"#fff",
    borderRadius:"5px",
    boxShadow:"0 3px 10px 0 rgb(0 0 0 / 5%)"
  },
  tableStyles: {
    padding: "3px 0 0 15px"
  },
  table2Styles: {
    border: "1px solid",
  },
  labelWidth: {
    display: "inline-block",
    width: "30%",
    fontWeight: "bold",
  },
  typeWidth: {
    margin: theme.spacing(5),
    "& .MuiFormControl-root": {
      width: "44%",
    },
  },
  verticalAlign: {
    verticalAlign: "baseline",
  },
  borderTable1: {
    border: "1px solid #d1d1d1",
    margin: "0 0px",
    padding: theme.spacing(2),
    backgroundColor:"#fff",
    borderRadius:"5px",
    boxShadow:"0 3px 10px 0 rgb(0 0 0 / 5%)"
  },
}))
const data = [
  { label: "Respiratory System",name: "RespiratorySystem", type: "text", size: 12, tableType: 6 ,value:{RespiratorySystem:"",RespiratorySystemAB:"Nomral/Abnormal/NA"}},
  { label: "Cardio - Vascular System",name: "CardioVascularSystem",type: "text",size: 12,tableType: 6,value:{CardioVascularSystem:"",CardioVascularSystemAB:"Nomral/Abnormal/NA"}},
  { label: "Central Nervous System",name: "CentralNervousSystem", type: "text", size: 12, tableType: 6 ,value:{CentralNervousSystem:"",CentralNervousSystemAB:"Nomral/Abnormal/NA"}},
  { label: "Abdomen",name: "Abdomen", type: "text", size: 12, tableType: 6,value:{Abdomen:"",AbdomenAB:"Nomral/Abnormal/NA"} },
  { label: "Skin",name: "Skin", type: "text", size: 12, tableType: 6 ,value:{Skin:"",SkinAB:"Nomral/Abnormal/NA"}},
  { label: "ECG",name: "ECG", type: "text", size: 12, tableType: 6,value:{ECG:"",ECGAB:"Nomral/Abnormal/NA"} },
  { label: "PFT",name: "PFT", type: "text", size: 12, tableType: 6 ,value:{PFT:"",PFTAB:"Nomral/Abnormal/NA"}},
  { label: "Audiometry",name: "AGR", type: "text", size: 12, tableType: 6 ,value:{AGR:"",AGRAB:"Nomral/Abnormal/NA"}},
  // { label: "X-RAY",name: "XRAY", type: "text", size: 12, tableType: 6,value:{XRAY:"",XRAYAB:"Nomral/Abnormal/NA"}},
]

const TableSixth = props => {
  const classes = useStyles()
  const SelectStyles = useSelectStyles()

  const Item = props => {
    var tableStyles = classes.tableStyles
    const { border, size } = props
    var table2Styles = border ? classes.table2Styles : ""

    return (
      <>
      
        <Grid item sm={size} className={tableStyles + " " + table2Styles} >
          <Element {...props} />
        </Grid>
      </>
    )
  }
  
  const Element = props => {
    const { label, type, width,value,name } = props

    var verticalAlign = classes.verticalAlign
    var typeWidth = classes.typeWidth
    var labelWidth = classes.labelWidth

    if (["select"].includes(type)) {
      const { list } = props
      return (
        <>
          <span className={labelWidth}> {label}</span> :
          <span className={typeWidth}>
            <SelectFieldWrapper
              classes={SelectStyles.root}
              list={list}
              label={label}
              name={name}
              value={value}
            />
          </span>
        </>
      )
    }
    if (["date"].includes(type)) {
      return (
        <>
          <span className={labelWidth}> {label}</span> :
          <span className={typeWidth}>
            <DateFieldWrapper classes={SelectStyles.root} label={label} name={name} value={value}/>
          </span>
        </>
      )
    }
    if (["text"].includes(type)) {
      return (
        <div>
          <span className={labelWidth}> {label}</span> :
          <span className={typeWidth}>
            <SelectFieldWrapper
              classes={SelectStyles.root}
              list={["Abnormal","Normal",'NA']}
              label={label}
              name={name+"AB"}
              value={value[name+"AB"]}
            />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <TextAreaFieldWrapper
            
              width={width}
              classes={verticalAlign}
              label={label} name={name} value={value[name]} placeholder="Comments"
            />
          </span>
        </div>
      )
    }
    if (["number"].includes(type)) {
      return (
        <>
          <span className={labelWidth}> {label}</span> :
          <span className={typeWidth}>
            <NumberFieldWrapper type={type} label={label} name={name} value={value}/>
          </span>
        </>
      )
    }
  }

  const [fieldData, setFieldData]=useState(data)
  useEffect(() => {
    if(props.data!=undefined && props.data!={}){
      var dataFromDb = []
      fieldData.map((field,index)=>{
        var values = field["value"]
        Object.keys(values).map((header)=>{
          values[header] = props.data[header]==undefined?"":props.data[header]
        })
        field["value"] = values
        dataFromDb.push(field)
      })
      setFieldData(dataFromDb)
    }
    
  }, [props.data])

  return (
    <div style={{ pageBreakBefore: "always",paddingTop:"100px"}}>
      
      <Typography align='center' variant='h6'>
        SYSTEMIC EXAMINATION
      </Typography>
      <Container className={classes.borderTable1}>
        <Grid container>
          {data.map((eachfield, index) => {
            return (

              <Item {...eachfield} key={index} />
            )
            
          })}
        </Grid>
      </Container>
    </div>
  )
}

export default TableSixth
