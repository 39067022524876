import React, { useEffect, useState } from "react"
import Webcam from "react-webcam"
import { Button, Container, Grid } from "@material-ui/core"

const WebcamCapture = props => {
  const { width, height,value } = props
  const videoConstraints = {
    width,
    height,
    facingMode: "user",
  }
  const [cameraStatus, setCameraStatus] = useState(false)
  const webcamRef = React.useRef(null)
  const [img, setImg] = useState("")
  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot()
    setImg(imageSrc)
    setCameraStatus(false)
  }, [webcamRef])

  useEffect(() => {
    if(value!=undefined && value!=""){
      setCameraStatus(false)
      setImg(value)
    }
  
}, [props.data])

  return (
    <>
      {cameraStatus && (
        <Webcam
          audio={false}
          height={videoConstraints.height}
          ref={webcamRef}
          screenshotFormat='image/jpeg'
          width={videoConstraints.width}
          videoConstraints={videoConstraints}
        />
      )}
      {!cameraStatus && img !== "" ? (
        <div style={{marginBottom:"1px"}}>
          <img src={img} id="photoTaken" alt='snapshot' style={{maxHeight:"170px",width:"300px"}}/>
        </div>
      ) : (
        ""
      )}
      <Grid
        style={{ position: "absolute", bottom: 10 }}
        container
        justifyContent='center'
        spacing={1}
      >
        <Grid item>
          <Button
            variant='contained'
            className='hide'
            onClick={() => {
              setCameraStatus(true)
            }}
            color='secondary'
            size='small'
          >
            Open Camera
          </Button>
        </Grid>
        <Grid item>
          <Button
            className='hide'
            disabled={!cameraStatus}
            onClick={capture}
            variant='contained'
            color='secondary'
            size='small'
          >
            Take Screenshot
          </Button>
          <input type="text" style={{display:"none"}} id="ImageValue" name="ImageValue" value={img}></input>
        </Grid>
      </Grid>
    </>
  )
}
export default WebcamCapture
