import React, { useEffect, useState } from "react"
import { Container, Grid, makeStyles, Typography } from "@material-ui/core"
import { TextFieldWrapper } from "./Elements"

const useStyles = makeStyles(theme => ({
  tableStyles: {
    padding: "3px 0 0 15px"
  },
  borderTable2: {
    border: "1px solid #d1d1d1",
    margin: "15px 0px",
    padding: theme.spacing(2),
    backgroundColor:"#fff",
    borderRadius:"5px",
    boxShadow:"0 3px 10px 0 rgb(0 0 0 / 5%)"
  },
  labelWidth: {
    display: "inline-block",
    width: 300,
    fontWeight: "bold",
  },
  verticalAlign: {
    verticalAlign: "baseline",
  },
  table4Styles: {
    borderTop: "1px solid ",
  },
}))

const data = [
  {
    name:"Right",
    label: "RIGHT",
    type: "text",
    size: 3,
    tableType: 7,
    value:{RightDv:"",RightNv:"",RightColorVision:"",RightCorrection:""}
  },
  {
    name:"Left",
    label: "LEFT",
    type: "text",
    size: 3,
    tableType: 7,
    value:{LeftDv:"",LeftNv:"",LeftColorVision:"",LeftCorrection:""}
  },
  {
    name:"IR",
    label: "Impression/Recommendation",
    type: "text",
    size: 3,
    tableType: 7,
    value:{IRNv:""}
  },
  // {
  //   name:"ColorVision",
  //   label: "Colour Vision",
  //   type: "text",
  //   size: 5,
  //   tableType: 7,
  //   value:{ColorVisionNv:""}
  // },
]

const TableSeventh = props => {
  const classes = useStyles()
  var verticalAlign = classes.verticalAlign
console.log(props)
  const TextValuesComponent = props => {
    var { label, size,name,value } = props
    
    var table4Styles = classes.table4Styles
    const first2rows = ["LEFT", "RIGHT"].includes(label)
    const columnStyles = { border: "1px solid #d1d1d1",
    margin: "5px 0px",
    backgroundColor:"#fff",
    borderRadius:"5px",
    boxShadow:"0 3px 10px 0 rgb(0 0 0 / 5%)" }
    return (
      <Grid item sm={12} className={table4Styles}>
        
        <Grid container alignContent='center'>
          <Grid
            item
            sm={size}
            className={classes.labelWidth}
            style={{ paddingLeft: 10 }}
          >
            <span> {label}</span>
          </Grid>
          {[
            { name:"Dv", labelName: "DV", visible: false },
            { name:"Nv", labelName: "NV", visible: true },
            { name:"ColorVision", labelName: "COLOUR VISION", visible: false },
            { name:"Correction", labelName: "CORRECTION", visible: false },
          ].map((obj, index) => {
            
            if (first2rows || obj.visible) {
              //  because we have multiple columns
              var labelName = label + "_" + obj.labelName
              if(name!="IR"){
                return (
                  <Grid item sm={2} style={columnStyles} key={index}>
                    <TextFieldWrapper
                      label={labelName}
                      classes={verticalAlign}
                      disableUnderline={true}
                      name={name+obj.name}
                      value={value[name+obj.name]}
                    />
                  </Grid>
                )
              }else{
                return (
                  <Grid item sm={8} style={columnStyles} key={index}>
                    <TextFieldWrapper
                      label={labelName}
                      classes={verticalAlign}
                      disableUnderline={true}
                      name={name+obj.name}
                      value={value[name+obj.name]}
                    />
                  </Grid>
                )
              }
            }
          })}
        </Grid>
      </Grid>
    )
  }

  const [fieldData, setFieldData]=useState(data)

  useEffect(() => {
    if(props.data!=undefined && props.data!={}){
      var dataFromDb = []
      fieldData.map((field,index)=>{
        var values = field["value"]
        Object.keys(values).map((header)=>{
          values[header] = props.data[header]==undefined?"":props.data[header]
        })
        field["value"] = values

        dataFromDb.push(field)
      })
      setFieldData(dataFromDb)
    }
  
  }, [props.data])

  return (
    <>
      <Typography align='center' variant='h6'>
        VISUAL ACUITY
      </Typography>
      <Container className={classes.borderTable2}>
        <Grid container>
          <Grid item sm={3} />
          {["DV", "NV", "COLOUR VISION", "CORRECTION"].map((eachName, index) => {
            return (
              <Grid
                item
                sm={2}
                key={index}
                style={{ borderLeft: "1px solid black" }}
              >
                <Typography align='center' style={{ fontWeight: "bold" }}>
                  {eachName}
                </Typography>
              </Grid>
            )
          })}
        </Grid>
        <Grid container>
          {fieldData.map((eachfield, index) => {
            const prop = { ...eachfield }
            return <TextValuesComponent {...prop} key={index} />
          })}
        </Grid>
      </Container>
    </>
  )
}

export default TableSeventh
